import React from "react";

import bgImg from "../../assets/img/bg/home1.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const BannerDefault = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div id="rs-banner" className="rs-banner style1" style={bgStyle}>
        <div className="container">
          <div className="banner-content text-center">
            <h1 className="banner-title capitalize">
              التسجيل في مشروع التحول الرقمي الموحد للجامعات الليبية
            </h1>
            <div className="banner-btn">
              <a
                className="readon banner-style"
                href="https://lhems.ldl.ly/"
                target="_blank"
              >
                للتسجيل في المنظومة{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}
    </React.Fragment>
  );
};

export default BannerDefault;
