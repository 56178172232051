import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Url1 } from "../../constants/defaultValues";

const SliderStyleTwo = ({ items }) => {
  const sliderSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 12000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="rs-slider style1">
        <Slider {...sliderSettings}>
          {items &&
            items.map((item) => (
              <div key={item._id} className="slider-content slide1">
                <div className="container text-right">
                  <div className="sl-sub-title white-color">{item.title}</div>
                  <p
                    className=" white-color rtl"
                    dangerouslySetInnerHTML={{
                      __html: item.description.slice(0, 200),
                    }}
                  ></p>
                  <div className="sl-btn">
                    <Link
                      className="readon2 banner-style"
                      to={`/advs/${item._id} `}
                    >
                      المزيد من التفاصيل
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          <div className="slider-content slide1">
            <div className="container text-right">
              <div className="sl-sub-title white-color">
              جامعة الزاوية ترحب بزوار موقعها الالكتروني
              </div>
            
              <div className="sl-btn">
                <Link className="readon2 banner-style" to="/about-university">
                  المزيد من التفاصيل
                </Link>
              </div>
            </div>
          </div>
          
        </Slider>
      </div>
    </React.Fragment>
  );
};

export default SliderStyleTwo;
