import React, { useEffect } from "react";
import Forms from "./CategoriesSection";
import ScrollToTop from "../../components/Common/ScrollTop";
import { useDispatch, useSelector } from "react-redux";
import { getFilesList } from "../../redux/filesList/actions";

const CourseMain = ({ faculty }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    faculty
      ? dispatch(getFilesList(faculty))
      : dispatch(getFilesList("university"));
  }, []);
  const { filesItems } = useSelector((state) => state.filesListApp);

  return (
    <React.Fragment>
      {/* Forms Start */}
      <Forms filesItems={filesItems} />
      {/* Forms End */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default CourseMain;
