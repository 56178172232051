import React from "react";
import { Helmet } from "react-helmet-async";
import HeaderStyleTwo from "../../components/Layout/Header/HeaderStyleTwo";
import SearchModal from "../../components/Layout/Header/SearchModal";
import FooterStyleTwo from "../../components/Layout/Footer/FooterStyleTwo";
import HomeThreeMain from "./HomeThreeMain";
import OffWrap from "../../components/Layout/Header/OffWrap";

import Logo from "../../assets/img/logo/logo-dark.png";

const HomeThree = () => {
  return (
    <React.Fragment>
      <Helmet>
        {/* <!-- Google+ Meta Info --> */}
        {/* <!-- Twitter Meta Info --> */}
        {/* Title */}
        <title>جامعة الزاوية</title>
        <meta property="og:site_name" content="جامعة الزاوية" />
        <meta itemprop="name" content="جامعة الزاوية" />
        <meta name="twitter:title" content="جامعة الزاوية" />

        {/* Image */}
        <meta
          property="og:image"
          content="http://zu.edu.ly/images/static/logoz.png"
        />
        <meta
          itemprop="image"
          content="http://zu.edu.ly/images/static/logoz.png"
        />

        {/* Description */}
        <meta
          name="description"
          content="جامعة الزاوية هي إحدى الجامعات الحكومية المنتشرة في ربوع ليبيا. تأسست سنة  وهي عضو في اتحاد الجامعات العربية، واتحاد الجامعات الأفريقية، واتحاد الجامعات الإسلامية. تضم الجامعة 26 كلية موزعة في مدن ليبيا شاملة  للعديد من التخصصات "
        />
        <meta
          name="twitter:description"
          content="جامعة الزاوية هي إحدى الجامعات الحكومية المنتشرة في ربوع ليبيا. تأسست سنة  وهي عضو في اتحاد الجامعات العربية، واتحاد الجامعات الأفريقية، واتحاد الجامعات الإسلامية. تضم الجامعة 26 كلية موزعة في مدن ليبيا شاملة  للعديد من التخصصات "
        />

        {/* keyWords */}
        <meta
          name="keywords"
          content="university,zu,zawia,aZzawia,alzawia,جامعة,ليبيا,كلية,الزاوية,جامعة الزاوية,university,education,libya,zawia university,university of zawia,azawia,العلمي,بجامعة,المؤتمر العلمي,التدريس,بجامعة الزاوية,الطب البشري,هيئة التدريس التربية,زوارة,العجيلات ,الصحة العامة,الشريعة,القانون,نتيجة,الصيدلة الزاوية,الاقتصاد,الموارد الطبيعية,كلية البيطرة,"
        />

        {/* Url */}
        <link rel="canonical" href="https://zu.edu.ly" />
        <meta property="og:url" content="https://zu.edu.ly" />
        <meta name="twitter:url" content="https://zu.edu.ly" />
      </Helmet>
      <OffWrap />
      <HeaderStyleTwo
        parentMenu="home"
        TopBar="enable"
        headerNormalLogo={Logo}
        CanvasLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style2"
        TopBarClass="topbar-area dark-parimary-bg"
        emailAddress="info@zu.edu.ly"
        phoneNumber="(+218) 23-762682"
      />
      <HomeThreeMain />
      <FooterStyleTwo />
      <SearchModal />
    </React.Fragment>
  );
};

export default HomeThree;
