import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { Url } from "../../constants/defaultValues";

import { FACULTIES_LIST_GET_LIST, FACULTIES_LIST_GET_ONE } from "../contants";

import {
  getFacultiesListSuccess,
  getFacultiesListError,
  getFacultiesDetailsSuccess,
  getFacultiesDetailsError,
} from "./actions";

const getFacultyDetailsItemsRequest = async (url) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/faculties/${url}`);

function* getFacultyDetailsItems({ payload }) {
  try {
    const response = yield call(getFacultyDetailsItemsRequest, payload);
    yield put(getFacultiesDetailsSuccess(response.data));
  } catch (error) {
    yield put(getFacultiesDetailsError(error));
  }
}
const getFacultiesListRequest = async () =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/faculties`);

function* getFacultiesListItems() {
  try {
    const response = yield call(getFacultiesListRequest);
    yield put(getFacultiesListSuccess(response.data));
  } catch (error) {
    yield put(getFacultiesListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(FACULTIES_LIST_GET_LIST, getFacultiesListItems);
}

export function* watchGetOne() {
  yield takeEvery(FACULTIES_LIST_GET_ONE, getFacultyDetailsItems);
}
export default function* rootSaga() {
  yield all([fork(watchGetList), fork(watchGetOne)]);
}
