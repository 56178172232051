import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import { scaleRotate } from "react-burger-menu";

const CurriculumPart = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);
  const { academicProgramsItems } = useSelector(
    (state) => state.programsListApp
  );
  return (
    <div className="content">
      <Accordion className="accordion-box">
        {academicProgramsItems &&
          academicProgramsItems.map((ac, i) => (
            <AccordionItem className="accordion-item rtl" uuid={`a${i}`}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <button>
                    <Link to={`/acprograms/${ac._id}`}>{ac.title}</Link>
                  </button>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="card-body acc-content current">
                <p dangerouslySetInnerHTML={{ __html: ac.programObjectives }} />
              </AccordionItemPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </div>
  );
};

export default CurriculumPart;
