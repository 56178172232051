import React from "react";
import SearchWidget from "../../components/Widget/SearchWidget";
import RecentAdvsWidget from "../../components/Widget/RecentAdvsWidget";

const BlogSidebar = () => {
  return (
    <>
      <SearchWidget />
      <RecentAdvsWidget />
    </>
  );
};

export default BlogSidebar;
