import React, { useEffect } from "react";
import Slider from "react-slick";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleTestimonialThree from "../../components/Testimonial/SingleTestimonialThree";

// Testimonial Avatars
import { useDispatch, useSelector } from "react-redux";
import { Url } from "../../constants/defaultValues";
import { getArticlesListByTracking } from "../../redux/articlesList/actions";

const Testimonial = ({ faculty }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    faculty && dispatch(getArticlesListByTracking(faculty.Url));
  }, [faculty]);
  const { ArticlesItems } = useSelector((state) => state.articlesListApp);
  const testimonialSettings = {
    dots: true,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    ArticlesItems && (
      <React.Fragment>
        <div className="rs-testimonial style3 orange-color pt-102 md-pt-70 pb-60">
          <div className="container">
            <SectionTitle
              sectionClass="sec-title mb-60 text-center md-mb-30"
              subtitleClass="sub-title orange"
              subtitle="اقلام الكلية"
              titleClass="title mb-0"
              title="مقالات مختارة"
            />
            <div className="row">
              <Slider {...testimonialSettings}>
                {ArticlesItems.map((article) => (
                  <SingleTestimonialThree
                    itemClass="testi-item rtl"
                    authorImage={Url + article.image}
                    Title={article.name}
                    Designation={article.adjective}
                    Description={article.details}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default Testimonial;
