import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// Image
import aboutImg from "../../assets/img/about/history.png";
import tabImg1 from "../../assets/img/about/tab1.jpg";
import tabImg2 from "../../assets/img/about/tab2.jpg";
import tabImg3 from "../../assets/img/about/tab3.jpg";

const AboutTab = () => {
  let tab1 = "رؤية الجامعة",
    tab2 = "رسالة & قيم الجامعة ",
    tab3 = "اهداف الجامعة";
  const tabStyle = "nav nav-tabs histort-part";

  return (
    <div className="rs-about style1 pt-100 pb-100 md-pt-70 md-pb-70">
      <div className="container">
        <Tabs>
          <div className="row align-items-center rtl">
            <div className="col-lg-6 padding-0 md-pl-14 md-pr-14 md-mb-30 relative ">
              <div className="img-part">
                <img src={aboutImg} alt="About Image" />
              </div>
              <TabList className={tabStyle}>
                <Tab>
                  <button>
                    <span className="icon-part">
                      <i className="flaticon-eye"></i>
                    </span>
                    {tab1}
                  </button>
                </Tab>
                <Tab>
                  <button>
                    <span className="icon-part">
                      <i className="flaticon-flower"></i>
                    </span>
                    {tab2}
                  </button>
                </Tab>
                <Tab>
                  <button>
                    <span className="icon-part">
                      <i className="flaticon-analysis"></i>
                    </span>
                    {tab3}
                  </button>
                </Tab>
              </TabList>
            </div>
            <div className="col-lg-6  md-pl-14">
              <TabPanel>
                <div className="about-history">
                  <div className="sec-title mb-24">
                    <h2 className="title">رؤية الجامعة</h2>
                    <div className="desc">
                      أن تكون الجامعة صرحاً علمياً متميزاً ورائداً يساهم بفعالية
                      في بناء المجتمع.
                    </div>
                  </div>
                  <div className="tab-img">
                    <img src={tabImg1} alt="Tab Image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="about-mission">
                  <div className="sec-title mb-24">
                    <h2 className="title">رسالة الجامعة</h2>
                    <div className="desc">
                      تسعى جامعة الزاوية لتقديم خدمات تعليمية وبحثية متميزة تخدم
                      المجتمع، من خلال كوادر بشرية مؤهلة، وبرامج علمية متطورة،
                      وبيئة تعليمية محفزة، وشراكات فاعلة محلياً واقليمياً
                      ودولياً تلبي متطلبات سوق العمل وتراعي معايير الجودة
                      والإعتماد.
                    </div>
                  </div>
                  <div className="tab-img">
                    <img src={tabImg2} alt="Tab Image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="about-admin">
                  <div className="sec-title mb-24">
                    <h2 className="title">اهداف الجامعة</h2>
                    <div className="desc">
                      <ul>
                        <li>
                          <i className="flaticon-left-arrow"></i> تقديم برامج
                          تعليمية في مختلف المجالات حسب معايير الجودة المحلية
                          والدولية.
                        </li>
                        <li>
                          <i className="flaticon-left-arrow"></i> الرفع من مستوى
                          البحث العلمي.
                        </li>
                        <li>
                          <i className="flaticon-left-arrow"></i> تعزيز دور
                          الجامعة في خدمة المجتمع.
                        </li>
                        <li>
                          <i className="flaticon-left-arrow"></i> توفير بيئة
                          مناسبة وتحسين مستوى الخدمات.
                        </li>
                        <li>
                          <i className="flaticon-left-arrow"></i> وضع سياسات
                          وبرامج مستمرة لتأهيل وتطوير الكوادر البشرية بالجامعة.
                        </li>
                        <li>
                          <i className="flaticon-left-arrow"></i> ربط مخرجات
                          الجامعة بمتطلبات سوق العمل.
                        </li>
                        <li>
                          <i className="flaticon-left-arrow"></i> دعم وتفعيل
                          التعاون المشترك بين الجامعة والمؤسسات المحلية
                          والإقليمية والدولية.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-img">
                    <img src={tabImg3} alt="Tab Image" />
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default AboutTab;
