import React from "react";
import { Link } from "react-router-dom";

const CategoriesSingleTwo = (props) => {
  const { categoriesClass, iconImg, catLink, title, courseQuantity } = props;

  return (
    <a
      target="_blank"
      className={categoriesClass ? categoriesClass : "categories-item"}
      href={catLink ? catLink : "/forms-evidence"}
    >
      <div className="icon-part">
        <img src={iconImg} alt={title} />
      </div>
      <div className="content-part">
        <h4 className="title">{title ? title : "General Education"}</h4>
        <span className="courses">{courseQuantity}</span>
      </div>
    </a>
  );
};

export default CategoriesSingleTwo;
