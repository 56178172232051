import React, { useEffect } from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import TeamSingleMain from "./TeamSingleMain";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";
import { Helmet } from "react-helmet-async";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";
import { useDispatch, useSelector } from "react-redux";

import { shareUrl, Url1 } from "../../constants/defaultValues";
import { getArticleItem } from "../../redux/articlesList/actions";

const TeamSingle = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getArticleItem(match.params.id));
  }, []);
  const { articleDetails } = useSelector((state) => state.articlesListApp);
  return (
    articleDetails && (
      <React.Fragment>
        <OffWrap />
        <Helmet>
          {/* <!-- Google+ Meta Info --> */}
          {/* <!-- Twitter Meta Info --> */}
          {/* Title */}
          <title>{articleDetails.title}</title>
          {/* <meta property="og:site_name" content={advDetails.title} />
          <meta itemprop="name" content={advDetails.title} />
          <meta name="twitter:title" content={advDetails.title} /> */}

          {/* Image */}
          {/* <meta property="og:image" content={Url1 + advDetails.image} />
          <meta itemprop="image" content={Url1 + advDetails.image} /> */}

          {/* Description */}
          {/* <meta
            name="description"
            content={advDetails.description.substr(3, 30)}
          />
          <meta
            name="twitter:description"
            content={advDetails.description.substr(3, 30)}
          /> */}

          {/* keyWords */}
          {/* <meta
          //   name="keywords"
          //   content={`${advDetails.title} , ${
          //     advDetails.nameOFTracking ? advDetails.nameOFTracking : ""
          //   }   
          //   ,
          //   جامعة الزاوية
          //   ,
          //   اعلانات الجامعة
          //   ,
          //   اهم نشاطات جامعة الزاوية
          //   `}
          // />

          {/* Url */}

          {/* <link rel="canonical" href={`${shareUrl}advs/${advDetails._id}`} />
          <meta
            property="og:url"
            content={`${shareUrl}advs/${advDetails._id}`}
          />
          <meta
            name="twitter:url"
            content={`${shareUrl}advs/${advDetails._id}`}
          /> */}

          <meta
            property="og:url"
            content={`${shareUrl}advs/${articleDetails._id}`}
          />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={articleDetails.title} />
          <meta
            property="og:description"
            content={articleDetails.description}
          />
          <meta
            property="og:image:secure_url"
            content={Url1 + articleDetails.image}
          />
        </Helmet>
        <Header
          parentMenu="pages"
          secondParentMenu="team"
          headerNormalLogo={Logo}
          headerStickyLogo={Logo}
          CanvasLogo={Logo}
          mobileNormalLogo={Logo}
          CanvasClass="right_menu_togle hidden-md"
          headerClass="full-width-header header-style1 home8-style4 rtl"
          TopBar="enable"
          TopBarClass="topbar-area home8-topbar"
          emailAddress="info@zu.edu.ly"
          phoneNumber="(+218) 23-762682"
        />

        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle={articleDetails && articleDetails.title}
          pageName="المقالات"
          breadcrumbsImg={bannerbg}
        />
        {/* breadcrumb-area-End */}

        {/* TeamDetails Start */}
        <TeamSingleMain article={articleDetails} />
        {/* TeamDetails End */}

        <Footer
          footerClass="rs-footer home9-style main-home"
          footerLogo={footerLogo}
        />

        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}

        <SearchModal />
      </React.Fragment>
    )
  );
};

export default TeamSingle;
