import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import { scaleRotate } from "react-burger-menu";
import { Url1 } from "../../constants/defaultValues";

const CurriculumPart = ({ plans }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="content">
      <Accordion className="accordion-box">
        {plans &&
          plans.map((plan, i) => (
            <AccordionItem className="accordion-item rtl" uuid={`a${i}`}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <button>
                    <a href={Url1 + plan.file} target="_blank">
                      <i className="fa fa-download  m-2"></i>
                      {plan.title}
                    </a>
                  </button>
                </AccordionItemButton>
              </AccordionItemHeading>
            </AccordionItem>
          ))}
      </Accordion>
    </div>
  );
};

export default CurriculumPart;
