import React from "react";
import { Link } from "react-router-dom";

import defaultLogo from "../../../assets/img/logo/logo.png";
import defaultBG from "../../../assets/img/bg/footer-bg.png";

const FooterStyleTwo = (props) => {
  const {
    footerClass,
    footerTopClass,
    footerLogo,
    footerBG,
    footerColor,
    facebook,
    linkedin,
    twitter,
    youtube,
    instagram,
  } = props;

  const footerBGStyle = {
    backgroundImage: `url(${footerBG})`,
    backgroundColor: `url(${footerColor})`,
  };
  const defaultBGStyle = {
    backgroundImage: `url(${defaultBG})`,
    backgroundColor: "#264931",
    // backgroundColor: "#273c66",
  };

  return (
    <footer
      id="rs-footer"
      className={footerClass ? footerClass : "rs-footer"}
      style={footerBG && footerColor ? footerBGStyle : defaultBGStyle}
    >
      <div className={footerTopClass ? footerTopClass : "footer-top"}>
        <div className="container rtl">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h4 className="widget-title">الخدمات الالكترونية</h4>
              <ul className="site-map">
                <li>
                  <a
                    href="https://elearning.zu.edu.ly/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    التعليم الالكتروني
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.esems.zu.edu.ly/esems/SystemsInterface"
                    target="_blank"
                    rel="noreferrer"
                  >
                    بوابة الطلاب
                  </a>
                </li>
                <li>
                  <a
                    href="https://pg.zu.edu.ly/auth/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    بوابة الدراسات العليا
                  </a>
                </li>
                <li>
                  <a
                    href="https://eportal.zu.edu.ly/auth/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    بوابة منتسبي الجامعة{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="https://dspace.zu.edu.ly/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    المستودع الرقمي
                  </a>
                </li>
                <li>
                  <a
                    href="http://zu.edu.ly/university"
                    target="_blank"
                    rel="noreferrer"
                  >
                  الاصدار القديم للموقع
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h4 className="widget-title">روابط داخلية</h4>
              <ul className="site-map">
                <li>
                  <Link to="/about">عن الجامعة</Link>
                </li>
                <li>
                  <Link to="/news">الاخبار </Link>
                </li>
                <li>
                  <Link to="/advs">الاعلانات</Link>
                </li>
                <li>
                  <Link to="/">نشاطات الجامعة</Link>
                </li>
                <li>
                  <Link to="/forms-evidence">نماذج تهمك</Link>
                </li>

                {/* <li>
                  <Link to="/">Web Design</Link>
                </li> */}
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h4 className="widget-title">مراكز الجامعة</h4>
              <ul className="site-map">
                {/* <li>
                  <Link to="/contact">Become A Teacher</Link>
                </li> */}
                {/* <li>
                  <Link to="/team/team-single">مركز المعلومات التوثيق</Link>
                </li> */}
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
              <h4 className="widget-title">اتصل بنا</h4>
              <ul className="address-widget address2">
                <li>
                  <i className="flaticon-location"></i>
                  <div className="desc m-4">الزاوية</div>
                </li>
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc m-4">
                    <a href="tel:0021823762659"> 0021823762659</a>
                    <a href="tel:0021823762682">0021823762682</a>
                    <a href="tel:0021823762382">0021823762382</a>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email "></i>
                  <div className="desc m-4">
                    <a href="mailto:info@zu.edu.ly">info@zu.edu.ly</a>
                    <a href="www.zu.edu.ly">www.zu.edu.ly</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-4 md-mb-20">
              <div className="footer-logo">
                <Link to="/" as="/">
                  <img src={footerLogo ? footerLogo : defaultLogo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 md-mb-20">
              <div className="copyright text-center md-text-left">
                <p> © جامعة الزاوية {new Date().getFullYear()}</p>
              </div>
            </div>
            <div className="col-lg-4 text-right md-text-left">
              <ul className="footer-social">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      facebook
                        ? facebook
                        : "https://www.facebook.com/%D8%AC%D8%A7%D9%85%D8%B9%D8%A9-%D8%A7%D9%84%D8%B2%D8%A7%D9%88%D9%8A%D8%A9-University-of-Zawia-506936242671203/"
                    }
                  >
                    <i className="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      twitter ? twitter : "https://twitter.com/UZawia?lang=en"
                    }
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      linkedin
                        ? linkedin
                        : "https://www.linkedin.com/in/university-of-zawia-437b63183/"
                    }
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      instagram
                        ? instagram
                        : "https://www.instagram.com/uzawia/"
                    }
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      youtube
                        ? youtube
                        : "https://www.youtube.com/channel/UCDYOZzNmGiaihNFxzzdNwkw?view_as=subscriber"
                    }
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterStyleTwo;
