/* eslint-disable no-underscore-dangle */
import {
  NEWS_LIST_GET_LIST,
  NEWS_LIST_GET_LIST_SUCCESS,
  NEWS_LIST_GET_LIST_ERROR,
  NEWS_LIST_GET_LIST_BY_TRACKING,
  NEWS_LIST_GET_LIST_BY_TRACKING_SUCCESS,
  NEWS_LIST_GET_LIST_BY_TRACKING_ERROR,
  NEWS_LIST_GET_ONE,
  NEWS_LIST_GET_ONE_SUCCESS,
  NEWS_LIST_GET_ONE_ERROR,
  NEWS_LIST_GET_LIST_WITH_FILTER,
  NEWS_LIST_GET_LIST_WITH_ORDER,
  NEWS_LIST_GET_LIST_SEARCH,
  NEWS_LIST_GET_TOTALS_SUCCESS,
  NEWS_LIST_GET_TOTALS_ERROR,
} from "../contants";

const INIT_STATE = {
  allNewsItems: null,
  newsItems: null,
  newsDetails: null,
  currentPage: 1,
  pageSize: 4,
  totalItem: 0,
  totalPage: 7,
  error: "",

  filter: null,
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  departments: null,
  labels: [
    { label: "الفصل الاول", color: "secondary", year: "1" },
    { label: "الفصل التاني", color: "primary", year: "2" },
    { label: "الفصل الثالث", color: "info", year: "3" },
    { label: "الفصل الرابع", color: "secondary", year: "4" },
    { label: "الفصل الخامس", color: "primary", year: "5" },
    { label: "الفصل السادس", color: "info", year: "6" },
  ],
  orderColumns: [
    { column: "subjectname", label: "عنوان المقرر" },
    { column: "departmentName", label: " القسم" },
    { column: "unit", label: "عدد الوحدات" },
  ],
  categories: ["داعمة", "تخصص", "عامة"],
  selectedItems: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case NEWS_LIST_GET_ONE:
    case NEWS_LIST_GET_LIST:
    case NEWS_LIST_GET_LIST_BY_TRACKING:
      return { ...state, loading: false };
    case NEWS_LIST_GET_TOTALS_SUCCESS:
      return {
        ...state,
        totalItem: payload.totalItem,
        totalPage: payload.totalPage,
      };

    case NEWS_LIST_GET_LIST_BY_TRACKING_SUCCESS:
    case NEWS_LIST_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        currentPage: payload.currentPage,
        pageSize: payload.pageSize,
        allNewsItems: payload.data,
        newsItems: payload.data,
      };
    case NEWS_LIST_GET_ONE_SUCCESS:
      return {
        ...state,
        loading: true,
        newsDetails: action.payload,
      };
    case NEWS_LIST_GET_TOTALS_ERROR:
    case NEWS_LIST_GET_LIST_BY_TRACKING_ERROR:
    case NEWS_LIST_GET_ONE_ERROR:
    case NEWS_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case NEWS_LIST_GET_LIST_WITH_FILTER:
      if (action.payload.column === "" || action.payload.value === "") {
        return {
          ...state,
          loading: true,
          newsItems: state.allNewsItems,
          filter: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const filteredItems = state.allNewsItems.filter(
        (item) =>
          item[action.payload.column] &&
          item[action.payload.column].toString() ===
            action.payload.value.toString()
      );
      return {
        ...state,
        loading: true,
        newsItems: filteredItems,
        filter: {
          column: action.payload.column,
          value: action.payload.value,
        },
      };

    case NEWS_LIST_GET_LIST_WITH_ORDER:
      if (action.payload === "") {
        return {
          ...state,
          loading: true,
          newsItems: state.newsItems,
          orderColumn: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const sortedItems = state.newsItems.sort((a, b) => {
        if (a[action.payload] < b[action.payload]) return -1;
        if (a[action.payload] > b[action.payload]) return 1;
        return 0;
      });
      return {
        ...state,
        loading: true,
        newsItems: sortedItems,
        orderColumn: state.orderColumns.find(
          (x) => x.column === action.payload
        ),
      };

    case NEWS_LIST_GET_LIST_SEARCH:
      if (action.payload === "") {
        return { ...state, newsItems: state.allNewsItems };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchItems = state.allNewsItems.filter(
        (item) =>
          (item.title && item.title.toLowerCase().indexOf(keyword) > -1) ||
          (item.description &&
            item.description.toLowerCase().indexOf(keyword) > -1) ||
          (item.nameOfTracking &&
            item.nameOfTracking.toLowerCase().indexOf(keyword) > -1)
      );
      return {
        ...state,
        loading: true,
        newsItems: searchItems,
        searchKeyword: action.payload,
      };

    default:
      return { ...state };
  }
};
