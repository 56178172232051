import React from "react";
import { Helmet } from "react-helmet";
import HeaderStyleTwo from "../../components/Layout/Header/HeaderStyleTwo";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import HomeTwoMain from "./HomeTwoMain";
import FooterStyleTwo from "../../components/Layout/Footer/FooterStyleTwo";

import favIcon from "../../assets/img/fav.png";

import Logo from "../../assets/img/logo/logo-dark.png";
import stickyLogo from "../../assets/img/logo/logo-dark.png";
import mobileLogo from "../../assets/img/logo/logo-dark.png";
import canvasLogo from "../../assets/img/logo/logo-dark.png";

const HomeTwo = () => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <HeaderStyleTwo
        parentMenu="home"
        TopBar="enable"
        headerNormalLogo={Logo}
        CanvasLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style2"
        TopBarClass="topbar-area dark-parimary-bg"
        emailAddress="info@zu.edu.ly"
        phoneNumber="(+218) 23-762682"
      />
      <HomeTwoMain />
      <FooterStyleTwo />
      <SearchModal />
    </React.Fragment>
  );
};

export default HomeTwo;
