import React from "react";
import CourseSidebar from "./CourseSidebar";
import CourseDetailsTab from "./CourseDetailsTab";
import { useSelector } from "react-redux";

const CourseDetailsPart = () => {
  const { academicProgramDetails } = useSelector(
    (state) => state.programsListApp
  );
  const { filesItems } = useSelector((state) => state.filesListApp);
  const plans = filesItems && filesItems.filter((file) => file.type === "plan");
  const program =
    filesItems && filesItems.filter((file) => file.type === "program");
  const course =
    filesItems && filesItems.filter((file) => file.type === "course");
  console.log(course);
  return (
    <React.Fragment>
      <div className="intro-section gray-bg pt-94 pb-100 md-pt-80 md-pb-80 loaded">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-8 md-mb-50">
              <CourseDetailsTab
                {...academicProgramDetails}
                plans={plans && plans}
                course={course && course}
              />
            </div>
            <div className="video-column col-lg-4">
              <CourseSidebar
                {...academicProgramDetails}
                program={program && program}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseDetailsPart;
