import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { Url } from "../../constants/defaultValues";

import {
  ADVS_LIST_GET_LIST,
  ADVS_LIST_GET_ONE,
  ADVS_LIST_GET_LIST_BY_TRACKING,
  ADVS_LIST_GET_TOTALS,
  ADVS_LIST_GET_IMPORTANT,
} from "../contants";

import {
  getAdvsListSuccess,
  getAdvsListError,
  getOneAdvSuccess,
  getOneAdvError,
  getAdvsListByTrackingError,
  getAdvsListByTrackingSuccess,
  getTotalsSuccess,
  getTotalsError,
  getImportantAdvsSuccess,
  getImportantAdvsError,
} from "./actions";

const getAdvsListRequest = async ({ selectedPageSize, currentPage }) =>
  // eslint-disable-next-line no-return-await
  await axios.get(
    `${Url}api/advs/list?pageSize=${selectedPageSize}&currentPage=${currentPage}`
  );

function* getAdvsListItems({ payload }) {
  try {
    const response = yield call(getAdvsListRequest, payload);
    yield put(getAdvsListSuccess(response.data));
  } catch (error) {
    yield put(getAdvsListError(error));
  }
}

const getOneAdvItemRequest = async (url) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/advs/${url}`);

function* getOneAdvItem({ payload }) {
  try {
    const response = yield call(getOneAdvItemRequest, payload);
    yield put(getOneAdvSuccess(response.data));
  } catch (error) {
    yield put(getOneAdvError(error));
  }
}
const getImportantAdvItemRequest = async (url) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/advs/important`);

function* getImportantAdvsItems({ payload }) {
  try {
    const response = yield call(getImportantAdvItemRequest, payload);
    yield put(getImportantAdvsSuccess(response.data));
  } catch (error) {
    yield put(getImportantAdvsError(error));
  }
}

const getAdvsItemByTrackingRequest = async (tracking) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/advs/tracking/${tracking}`);

function* getAdvsListItemsByTracking({ payload }) {
  try {
    const response = yield call(getAdvsItemByTrackingRequest, payload);
    yield put(getAdvsListByTrackingSuccess(response.data));
  } catch (error) {
    yield put(getAdvsListByTrackingError(error));
  }
}

const getAdvsTotalItemsRequest = async () =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/advs/totals`);

function* getAdvsTotalItems() {
  try {
    const response = yield call(getAdvsTotalItemsRequest);
    yield put(getTotalsSuccess(response.data));
  } catch (error) {
    yield put(getTotalsError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(ADVS_LIST_GET_LIST, getAdvsListItems);
}

export function* watchGetImportant() {
  yield takeEvery(ADVS_LIST_GET_IMPORTANT, getImportantAdvsItems);
}

export function* watchGetOne() {
  yield takeEvery(ADVS_LIST_GET_ONE, getOneAdvItem);
}

export function* watchGetListByTracking() {
  yield takeEvery(ADVS_LIST_GET_LIST_BY_TRACKING, getAdvsListItemsByTracking);
}

export function* watchGetTotalItems() {
  yield takeEvery(ADVS_LIST_GET_TOTALS, getAdvsTotalItems);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetListByTracking),
    fork(watchGetTotalItems),
    fork(watchGetImportant),
  ]);
}
