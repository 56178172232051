// eslint-disable-next-line import/no-cycle
import {
  NEWS_LIST_GET_LIST,
  NEWS_LIST_GET_LIST_SUCCESS,
  NEWS_LIST_GET_LIST_ERROR,
  NEWS_LIST_GET_TOTALS,
  NEWS_LIST_GET_TOTALS_SUCCESS,
  NEWS_LIST_GET_TOTALS_ERROR,
  NEWS_LIST_GET_LIST_BY_TRACKING,
  NEWS_LIST_GET_LIST_BY_TRACKING_SUCCESS,
  NEWS_LIST_GET_LIST_BY_TRACKING_ERROR,
  NEWS_LIST_GET_ONE,
  NEWS_LIST_GET_ONE_SUCCESS,
  NEWS_LIST_GET_ONE_ERROR,
  NEWS_LIST_GET_LIST_WITH_FILTER,
  NEWS_LIST_GET_LIST_WITH_ORDER,
  NEWS_LIST_GET_LIST_SEARCH,
} from "../contants";

export const getTotals = () => ({
  type: NEWS_LIST_GET_TOTALS,
});

export const getTotalsSuccess = (item) => ({
  type: NEWS_LIST_GET_TOTALS_SUCCESS,
  payload: item,
});

export const getTotalsError = (error) => ({
  type: NEWS_LIST_GET_TOTALS_ERROR,
  payload: error,
});

export const getOneNews = (id) => ({
  type: NEWS_LIST_GET_ONE,
  payload: id,
});

export const getOneNewsSuccess = (item) => ({
  type: NEWS_LIST_GET_ONE_SUCCESS,
  payload: item,
});

export const getOneNewsError = (error) => ({
  type: NEWS_LIST_GET_ONE_ERROR,
  payload: error,
});

export const getNewsList = (selectedPageSize, currentPage) => ({
  type: NEWS_LIST_GET_LIST,
  payload: { selectedPageSize, currentPage },
});

export const getNewsListSuccess = (items) => ({
  type: NEWS_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getNewsListError = (error) => ({
  type: NEWS_LIST_GET_LIST_ERROR,
  payload: error,
});
export const getNewsListByTracking = (
  tracking,
  selectedPageSize,
  currentPage
) => ({
  type: NEWS_LIST_GET_LIST_BY_TRACKING,
  payload: { tracking, selectedPageSize, currentPage },
});

export const getNewsListByTrackingSuccess = (items) => ({
  type: NEWS_LIST_GET_LIST_BY_TRACKING_SUCCESS,
  payload: items,
});

export const getNewsListByTrackingError = (error) => ({
  type: NEWS_LIST_GET_LIST_BY_TRACKING_ERROR,
  payload: error,
});

export const getNewsListWithFilter = (column, value) => ({
  type: NEWS_LIST_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getNewsListWithOrder = (column) => ({
  type: NEWS_LIST_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getNewsListSearch = (keyword) => ({
  type: NEWS_LIST_GET_LIST_SEARCH,
  payload: keyword,
});
