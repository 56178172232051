import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import SectionTitle from "../../components/Common/SectionTitle";

const Cta = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="rs-cta style2">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="YLN1Argi7ik"
        onClose={() => {
          openModal();
        }}
      />
      <div className="partition-bg-wrap home2">
        <div className="container">
          <div className="row y-bottom">
            <div
              className="col-lg-6  pt-134 pb-150 md-pl-14 md-pt-44 md-pb-50"
            >
              <SectionTitle
                sectionClass="sec-title mb-40 md-mb-20"
                titleClass="title mb-16 md-mb-10 "
                title="University Operational Plan (2022-2023)"
                descClass="desc text-justify text-white"
                description="Within the framework of providing advanced educational services, raising the level of scientific research and investment, and effectively contributing to the development of society, and based on the vision and mission of the university to draw a roadmap for the future, the university has started the strategic plan project (2019-2023 AD), where several strategic goals were set, and from each strategic goal a set of operational goals to achieve it emerges. Accordingly, an operational plan for the university (2022-2023 AD) was prepared so that it includes all the activities that achieve the operational goals, the time period for each activity, and the indicators necessary to measure the activities, as well as the entities affiliated with the university and entrusted with implementing each activity."
                />
              <div className="btn-part">
                <a className="readon2" href="/w-plan.pdf">
                  More ... 
                </a>
              </div>
            </div>
            <div
              className="col-lg-6  pb-150 md-pl-14 md-pt-44 md-pb-50"
            >
              <SectionTitle
                sectionClass="sec-title mb-40 md-mb-20"
                titleClass="title mb-16 md-mb-10"
                title="University Strategic Plan"
                descClass="desc text-justify"
                description="Zawiya University aspires to become a leading scientific beacon that contributes to sustainable development in the western region in particular, and throughout the country in general. The university works to raise its level regionally and internationally by focusing on academic reputation, distinguished scientific research, the university's openness to its surroundings, and local and international partnerships. Its mission revolves around three main elements: providing advanced educational services, raising the level of scientific research and investing in it, and contributing effectively to community development."
              />
              <div className="btn-part">
                <a className="readon2" href="/plan.pdf">
More ...
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
