/* eslint-disable import/no-unresolved */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { Url } from "../../constants/defaultValues";

import {
  ARTICLES_GET_LIST,
  ARTICLES_GET_ONE,
  ARTICLES_GET_LIST_BY_TRACKING,
} from "../contants";

import {
  getArticlesListByTrackingSuccess,
  getArticlesListByTrackingError,
  getArticlesListSuccess,
  getArticlesListError,
  getArticleItemSuccess,
  getArticleItemError,
} from "./actions";

const getArticlesByTrackingListRequest = async (tracking) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/Articles/${tracking}`);

function* getArticlesListByTrackingItems({ payload }) {
  try {
    const response = yield call(getArticlesByTrackingListRequest, payload);
    yield put(getArticlesListByTrackingSuccess(response.data));
  } catch (error) {
    yield put(getArticlesListByTrackingError(error));
  }
}
const getArticlesListRequest = async () =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/Articles`);

function* getArticlesListItems() {
  try {
    const response = yield call(getArticlesListRequest);
    yield put(getArticlesListSuccess(response.data));
  } catch (error) {
    yield put(getArticlesListError(error));
  }
}

const getArticleItemRequest = async (id) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/articles/details/${id}`);

function* getArticlesItem({ payload }) {
  try {
    const response = yield call(getArticleItemRequest, payload);
    yield put(getArticleItemSuccess(response.data));
  } catch (error) {
    yield put(getArticleItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(ARTICLES_GET_LIST, getArticlesListItems);
}
export function* watchGetListByTracking() {
  yield takeEvery(
    ARTICLES_GET_LIST_BY_TRACKING,
    getArticlesListByTrackingItems
  );
}

export function* wathcGetItem() {
  yield takeEvery(ARTICLES_GET_ONE, getArticlesItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcGetItem),
    fork(watchGetListByTracking),
  ]);
}
