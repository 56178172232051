import React from "react";
import NewsPost from "../../components/Widget/NewsPost";
import SearchWidget from "../../components/Widget/SearchWidget";

const SinglePostSidebar = () => {
  return (
    <>
      <SearchWidget />
      <NewsPost />
    </>
  );
};

export default SinglePostSidebar;
