import React from "react";
import CTA from "./CtaSection";
import Blog from "./BlogSection";
import About from "./AboutSection";
import Videos from "./VideoSection";
import Feature from "./FeatureSection";
import Categories from "./CategoriesSection";
import Testimonial from "./TestimonialSection";
import ScrollToTop from "../../components/Common/ScrollTop";
import OfficeBanner from "../../components/Banner/OfficeBanner";
import bgImg from "../../assets/img/banner/home9.jpg";

const HomeTenMain = () => {
  return (
    <React.Fragment>
      {/* Banner-area-start */}
      <OfficeBanner bgImg={bgImg} title="مركز المعلومات و التوثيق" />
      {/* Banner-area-end */}

      {/* Feature-area-start */}
      <Feature />
      {/* Feature-area-end */}

      {/* about-area-start */}
      <About />
      {/* about-area-end */}

      {/* Categories-area-start */}
      <Categories />
      {/* Categories-area-end */}

      {/* Video-area-start */}
      <Videos />
      {/* Video-area-end */}

      {/* testmonial-area-start */}
      <Testimonial />
      {/* testmonial-area-end */}

      {/* blog-area-start */}
      <Blog />
      {/* blog-area-end */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup " />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default HomeTenMain;
