import React from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import CourseMain from "./CourseMain";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";

const CourseTwo = ({ match }) => {
  const faculty = match.params.url;
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        faculty={faculty ? true : false}
        facultyId={faculty && faculty}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4 rtl"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="النماذج و الادلة"
        pageName="النماذج و الادلة"
        breadcrumbsImg={bannerbg}
        mainLink={faculty ? `/faculty/${faculty}` : null}
      />
      {/* breadcrumb-area-start */}

      {/* Course Main */}
      <CourseMain faculty={faculty && faculty} />
      {/* Course Main */}

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default CourseTwo;
