/* eslint-disable no-underscore-dangle */
import {
  ACADPROGRAM_GET_LIST,
  ACADPROGRAM_GET_LIST_SUCCESS,
  ACADPROGRAM_GET_LIST_ERROR,
  ACADPROGRAM_GET_LIST_BY_TRACKING,
  ACADPROGRAM_GET_LIST_BY_TRACKING_SUCCESS,
  ACADPROGRAM_GET_LIST_BY_TRACKING_ERROR,
  ACADPROGRAM_GET_ONE,
  ACADPROGRAM_GET_ONE_SUCCESS,
  ACADPROGRAM_GET_ONE_ERROR,
} from "../contants";

const INIT_STATE = {
  allProgramsItems: null,
  academicProgramsItems: null,
  academicProgramDetails: null,
  error: "",
  loading: false,
  selectedItems: [],
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  orderColumns: [
    { column: "name", label: "اسم البرنامج" },
    { column: "title", label: " القسم" },
  ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACADPROGRAM_GET_ONE:
    case ACADPROGRAM_GET_LIST_BY_TRACKING:
    case ACADPROGRAM_GET_LIST:
      return { ...state, loading: false };

    case ACADPROGRAM_GET_LIST_BY_TRACKING_SUCCESS:
    case ACADPROGRAM_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allProgramsItems: action.payload,
        academicProgramsItems: action.payload,
      };

    case ACADPROGRAM_GET_ONE_SUCCESS:
      return {
        ...state,
        loading: true,
        academicProgramDetails: action.payload,
      };
    case ACADPROGRAM_GET_ONE_ERROR:
    case ACADPROGRAM_GET_LIST_BY_TRACKING_ERROR:
    case ACADPROGRAM_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
