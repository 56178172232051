import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
import CategoriesSingleFour from "../../components/Categories/CategoriesSingleFour";

// Categories Image

import CatImg1 from "../../assets/img/categories/home9/1.jpg";
import CatImg2 from "../../assets/img/categories/home9/2.jpg";
import CatImg3 from "../../assets/img/categories/home9/3.jpg";

const Categories = () => {
  return (
    <div className="rs-categories home9-style event-bg pt-100 pb-100 md-pt-70 md-pb-40">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title3 text-center mb-44"
          subtitleClass="sub-title yellow-color"
          titleClass="title yellow-color"
          title="فريق العمل"
        />

        <div className="row">
          <div className="col-md-4 md-mb-40">
            <CategoriesSingleFour
              categoriesClass="categories-items text-center"
              categoriesImg={CatImg1}
              categoriesIcon="fa fa-code"
              categoriesTitle="عدنان الخرزة"
              categoriesDesc=" مهندس برمجيات"
              btnText="الصفحة الشخصية"
            />
          </div>
          <div className="col-md-4 md-mb-40">
            <CategoriesSingleFour
              categoriesClass="categories-items text-center"
              categoriesImg={CatImg2}
              categoriesIcon="fa fa-laptop"
              categoriesTitle="أ. احمد ولد سليمان"
              categoriesDesc=" مدير مكتب الموقع الالكتروني "
              btnText="الصفحة الشخصية"
            />
          </div>

          <div className="col-md-4">
            <CategoriesSingleFour
              categoriesClass="categories-items text-center"
              categoriesImg={CatImg3}
              categoriesIcon="fa fa-user"
              categoriesTitle="أ. محمود البشتي"
              categoriesDesc="مدير مركز المعلومات و التوثيق"
              btnText="الصفحة الشخصية"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
