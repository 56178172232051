// eslint-disable-next-line import/no-cycle
import {
  CONFERENCES_GET_LIST,
  CONFERENCES_GET_LIST_SUCCESS,
  CONFERENCES_GET_LIST_ERROR,
} from "../contants";

export const getConferncesList = () => ({
  type: CONFERENCES_GET_LIST,
});

export const getConferncesListSuccess = (items) => ({
  type: CONFERENCES_GET_LIST_SUCCESS,
  payload: items,
});

export const getConferncesListError = (error) => ({
  type: CONFERENCES_GET_LIST_ERROR,
  payload: error,
});
