import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import Newsletter from "../../components/Common/Newsletter";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";

import BlogSidebar from "./BlogSidebar";
import SinglePostSix from "../../components/Blog/SingleNewsOfNews";

// Image
import favIcon from "../../assets/img/fav-orange.png";
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";
import loading from "../../assets/img/loading1.gif";
import bannerbg from "../../assets/img/breadcrumbs/21.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewsList,
  getNewsListByTracking,
  getTotals,
} from "../../redux/newsList/actions";
import { Url } from "../../constants/defaultValues";
import Pagination from "./Pagination";

const BlogLeft = ({ match }) => {
  const faculty = match.params.url;
  const dispatch = useDispatch();
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getTotals());
  }, []);
  useEffect(() => {
    faculty
      ? dispatch(getNewsListByTracking(faculty, selectedPageSize, currentPage))
      : dispatch(getNewsList(selectedPageSize, currentPage));
  }, [selectedPageSize, currentPage]);

  const { newsItems, totalPage } = useSelector((state) => state.newsListApp);

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="blog"
        secondParentMenu="blogSidebar"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        faculty={faculty ? true : false}
        facultyId={faculty && faculty}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4 rtl"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA"
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="اخر الاخبار"
        pageName="الاخبار"
        breadcrumbsImg={bannerbg}
        mainLink={faculty ? `/faculties/${faculty}` : null}
        parentCategory={faculty ? `الرجوع للكلية` : "الرئيسية"}
      />
      {/* breadcrumb-area-End */}

      {/* Blog-Part-Start */}
      <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 pr-50 md-pr-14">
              <div className="widget-area">
                <BlogSidebar />
              </div>
            </div>

            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12 mb-70 md-mb-50">
                  {newsItems ? (
                    newsItems.map((news) => (
                      <SinglePostSix
                        blogImage={news && Url + news.image}
                        blogAuthor={news && news.author && news.author.name}
                        blogPublishedDate={news && news.updatedAt}
                        blogTitle={news && news.title}
                        blogDesc={news && news.description}
                        newsTrackingLink={`/faculty/${news.tracking}`}
                        nameOFTracking={news && news.nameOFTracking}
                        blogButtonClass="blog-button"
                        blogButtonText="المزيد ..."
                        newsId={news && news._id}
                      />
                    ))
                  ) : (
                    <div className="text-center ">
                      <img src={loading} alt="grid img" width="300" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog-Part-End */}
      <Pagination
        currentPage={currentPage}
        totalPage={newsItems && newsItems.length > 9 ? totalPage : 1}
        onChangePage={setCurrentPage}
      />

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default BlogLeft;
