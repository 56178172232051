import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import ReactPlayer from "react-player";

import icon1 from "../../assets/img/categories/icons/1.png";

const About = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const openModal1 = () => setIsOpen(!isOpen);
  const openModal2 = () => setIsOpen1(!isOpen);
  const openModal3 = () => setIsOpen2(!isOpen);
  return (
    <div id="rs-about" className="rs-about style1 pb-100 md-pb-70 rtl">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="zuCcJ9jKa80"
        onClose={() => {
          openModal1();
        }}
      />
      <ModalVideo
        channel="youtube"
        isOpen={isOpen1}
        videoId="MQXYzUIlvUE"
        onClose={() => {
          openModal2();
        }}
      />
      <ModalVideo
        channel="youtube"
        isOpen={isOpen2}
        videoId="6D2vo17Gc2k"
        onClose={() => {
          openModal3();
        }}
      />

      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4 order-last">
            <div className="notice-bord style1">
              <h4 className="title">ملفات تهمك</h4>
              <ul>
                <li>
                  <div className="date">
                    <div className="icon-part">
                      <a href="/docs/doc1.pdf" target="_blank">
                        <img src={icon1} alt="icons" />
                      </a>{" "}
                    </div>
                  </div>
                  <div className="desc">
                    انشاء حساب طالب في النظام الموحّد للجامعات الليبية
                  </div>
                </li>
                <li>
                  <div className="date">
                    {" "}
                    <a href="/docs/doc2.pdf" target="_blank">
                      <img src={icon1} alt="icons" />
                    </a>{" "}
                  </div>
                  <div className="desc">
                    انشاء حساب معيد في النظام الموحّد للجامعات الليبية
                  </div>
                </li>
                <li>
                  <div className="date">
                    {" "}
                    <a href="/docs/doc3.pdf" target="_blank">
                      <img src={icon1} alt="icons" />
                    </a>
                  </div>
                  <div className="desc">
                    انشاء حساب موظف في النظام الموحّد للجامعات الليبية
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-lg-12 pr-50 md-pr-14">
            <div className="about-part">
              <div className="sec-title mb-40">
                <div className="sub-title primary">
                  تعلن جامعة الزاوية عن فتح باب التسجيل والقبول للطلبة المتحصلين
                  على الشهادة الثانوية للعام (2021-2022) للدراسة بكليات جامعة
                  الزاوية حسب الضوابط على النحو التالي :
                </div>
                <h2 className="title">
                  أولا : التنسيب مباشرة إلى الكليات التالية :-
                </h2>
                <div className="desc text-justify">
                  <li> كليات الآداب</li>
                  <li> كليات الإقتصاد </li>
                  <li> كليات التربية</li>
                  <li> كلية اللغات والترجمة </li>
                  <li> كليات القانون</li>
                </div>
                <h2 className="title">
                  ثانيا : التنسيب إلى المرحلة التمهيدية :-
                </h2>
                <div className="desc text-justify">
                  وهي المرحلة التي يدرس فيها الطالب المقرررات الدراسية المعتمدة
                  ويعد إنجازها من متطلبات تنسيبه إلى الكليات المستهدفة التالية :
                  <li>
                    {" "}
                    كليات المرحلة التمهيدية بالعلوم الطبية وتشمل كليات الطب
                    <br />
                    البشري -كلية الصيدلة -التقنية الطبية -الطب البيطري العجيلات
                    -كلية التمريض الزاوية-الصحة العامة العجيلات -قسم علم النبات
                    وعلم الحيوان بكلية العلوم -وبعض الاقسام بكلية الزراعة
                    العجيلات .
                  </li>
                  <li>
                    كليات المرحلة التمهيدية للعلوم الهندسية و التطبيقية وتشمل
                    <br />
                    الكليات التالية : كلية الهندسة الزاوية -كلية النفط والغاز
                    -كلية تقنية المعلومات - كلية الموارد الطبيعية العجيلات -
                    كلية الموارد بئر الغنم -وكليات العلوم -الزراعة
                  </li>
                </div>
                <h2 className="title">
                  ثالثا :المستندات المطلوبة للقبول بالكلية
                </h2>
                <div className="desc text-justify">
                  ملف معلق - الاستمارة الأصلية - شهادة ميلاد بالرقم الوطني - 4
                  صور شمسية - رقم التسجيل من المنظومة .
                </div>

                <h2 className="title">رابعاً :خطوات التسجبل</h2>
                <div className="desc text-justify">
                  <ol>
                    <li>الدخول الي الرابط التالي :-</li>
                    <br />
                    <div className="banner-content text-center">
                      <div className="banner-btn">
                        <a
                          className="readon banner-style"
                          href="http://esems.zu.edu.ly/student/auth/send-request"
                          target="_blank"
                        >
                          اضغط هنا لتسجيل{" "}
                        </a>
                      </div>
                    </div>
                    <br />
                    <h1>عند فتح الرابط اتبع الخطوات التالية:</h1>
                    <li> أكتب رقم القيد بالثانوية والضغط على تحقق.</li>
                    <li>
                      {" "}
                      تظهر الصفحة التالية والتي تطلب منك إدخال الرقم الوطني
                    </li>
                    <li> أكتب الرقم الوطني والضغط علي زر الدخول. </li>
                    <li>تظهر لك صفحه تحتوي علي بياناتك الاساسية. </li>
                    <li>قم بإكمال باقي البيانات ثم اضغط علي زر التالي.</li>
                    <li>
                      تجد في أسفل الصفحة زر ارسال يطلب الضغط عليه لكي يتم إرسال
                      طلبك للنظام
                    </li>
                    <li>يتم إصدار رقم تسجيل خاص بطلبك وعرضه علي الشاشة</li>
                  </ol>
                </div>
                <h2 className="title">ملاحظة :-</h2>
                <div className="desc text-justify">
                  <li>
                    يتم تسليم المستندات في الكلية المستهدفة من قبل الطالب ويستلم
                    الطالب ورقة استلام المستندات من الكلية المستهدفة لغرض
                    التصوير وإصدار بطاقة تعريف الالكتروني لاحقا .
                  </li>
                  <li>
                    يتم تسليم ملفات المرحلة التمهيدية بقسميها العلوم الطبية
                    والعلوم التطبيقية بإدارة مسجلي كليات العلوم{" "}
                  </li>
                </div>
              </div>
               
              <div>
                <h5>فيديو توضيح عن كيفية تسجيل الطلبة في المنظومة</h5>
                <ReactPlayer
                  url="https://youtu.be/XAF8X8Jwjl4"
                  controls
                  width="450"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
