import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";

import FooterStyleTwo from "../../components/Layout/Footer/FooterStyleTwo";
import HomeFiveMain from "./HomeFiveMain";

import favIcon from "../../assets/img/fav.png";
import Logo from "../../assets/img/logo/logo3.png";
import stickyLogo from "../../assets/img/logo/logo.png";
import darkLogo from "../../assets/img/logo/logo-dark.png";
import smallDeviceLogo from "../../assets/img/logo/logo-dark2.png";
import { getFacultiesDetails } from "../../redux/facultiesList/actions";
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentsList } from "../../redux/departmentList/actions";
import { getAdvsListByTracking } from "../../redux/advsList/actions";
import { getNewsListByTracking } from "../../redux/newsList/actions";
import { getMembersListByFaculty } from "../../redux/membersList/actions";

const HomeFive = ({ match }) => {
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMembersListByFaculty(match.params.url));
    dispatch(getFacultiesDetails(match.params.url));
    dispatch(getDepartmentsList(match.params.url));
    dispatch(getAdvsListByTracking(match.params.url));
    dispatch(getNewsListByTracking(match.params.url, 5, 1));
  }, []);
  const { facultyDetails } = useSelector((state) => state.facultiesListApp);

  const { membersItems } = useSelector((state) => state.memberlistApp);

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <HeaderStyleThree
        parentMenu="home"
        headerNormalLogo={Logo}
        headerStickyLogo={stickyLogo}
        CanvasLogo={darkLogo}
        responsiveLogo={smallDeviceLogo}
        faculty={true}
        facultyId={facultyDetails && facultyDetails.Url}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style3 modify rtl"
        TopBarClass="topbar-area dark-parimary-bg"
      />

      <HomeFiveMain facultyDetails={facultyDetails} members={membersItems} />

      <FooterStyleTwo {...facultyDetails} />
      <SearchModal />
    </React.Fragment>
  );
};

export default HomeFive;
