import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";

// Image
import { Url } from "../../constants/defaultValues";

const CourseSidebar = ({
  title,
  rooms,
  image,
  studySystem,
  studyYears,
  students,
  graduationProject,
  alumni,
  members,
  nameOFTracking,
  studyProgram,
  subjects,
  videoLink,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="inner-column">
      {videoLink && (
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId={videoLink}
          onClose={() => {
            openModal();
          }}
        />
      )}
      <div className="intro-video media-icon primaty-color2">
        <img className="video-img" src={Url + image} alt={title} />
        <Link
          className="popup-videos"
          onClick={() => {
            openModal();
          }}
        >
          <i className="fa fa-play"></i>
        </Link>
        <h4>رحلة حول القسم</h4>
      </div>
      <div className="course-features-info ">
        <div>
          <h5 className="label text-center">{title}</h5>
          <h6 className="label text-center">{nameOFTracking}</h6>
        </div>

        <ul>
          <li className="lectures-feature ">
            <span className="label">{studyProgram}</span>
            <span className="value"> البرنامج الاكاديمي</span>
          </li>
          <li className="lectures-feature ">
            <i className="fa fa-files-o"></i>
            <span className="label">{rooms}</span>
            <span className="value">عدد القاعات</span>
          </li>
          <li className="quizzes-feature">
            <i className="fa fa-puzzle-piece"></i>
            <span className="label">{studySystem}</span>
            <span className="value">نظام الدراسة</span>
          </li>
          <li className="duration-feature">
            <i className="fa fa-clock-o"></i>
            <span className="value">مدة الدراسة</span>
            <span className="label"> سنوات {studyYears} </span>
          </li>
          <li className="students-feature">
            <i className="fa fa-users"></i>
            <span className="label">{students}</span>
            <span className="value">عدد الطلبة</span>
          </li>
          <li className="students-feature">
            <i className="fa fa-users"></i>
            <span className="label">{alumni}</span>
            <span className="value">عدد الخريجين</span>
          </li>
          <li className="students-feature">
            <i className="fa fa-users"></i>
            <span className="label">{members}</span>
            <span className="value"> اعضاد هيئة التدريس</span>
          </li>
          <li className="assessments-feature">
            <i className="fa fa-check-square-o"></i>
            <span className="label">{graduationProject}</span>
            <span className="value">مشروع تخرج</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CourseSidebar;
