import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getArticlesList } from "../../redux/articlesList/actions";
import { Url } from "../../constants/defaultValues";
import SingleTestimonialTwo from "../../components/Testimonial/SingleArticle";
import SectionTitle from "../../components/Common/SectionTitle";

import AOS from "aos";
import { Link } from "react-router-dom";

const About = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    dispatch(getArticlesList());
  }, []);

  const { ArticlesItems } = useSelector((state) => state.articlesListApp);
  return (
    <div id="rs-about" className="rs-about style1 pb-100 md-pb-70 rtl">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 pr-50 md-pr-14">
            <div className="about-part">
              <div className="rs-testimonial style2 pt-100 pb-100 md-pt-70 md-pb-70 rtl">
                <div className="container">
                  <SectionTitle
                    sectionClass="sec-title mb-60 md-mb-30 text-center"
                    subtitleClass="sub-title primary"
                    subtitle="اقلام الجامعة"
                    titleClass="title mb-0"
                    title="مقالات مختارة"
                  />
                  <div className="row">
                    {ArticlesItems &&
                      ArticlesItems.slice(0, 1).map((article) => (
                        <div className="col-lg-5 pr-90 md-pr-14 md-mb-30">
                          <div className="donation-part" data-aos="fade-up">
                            <img src={Url + article.image} alt="Donor" />
                            <h3 className="title mb-10">
                              <Link to={`/articles/${article._id}`}>
                                {article.title}
                              </Link>{" "}
                            </h3>
                            <div
                              className="desc mb-38"
                              dangerouslySetInnerHTML={{
                                __html: article.details.substr(0, 300),
                              }}
                            ></div>
                          </div>
                        </div>
                      ))}
                    <div className="col-lg-7 lg-pl-0 ml--14 md-ml-0">
                      {ArticlesItems &&
                        ArticlesItems.slice(1, 3).map((article) => (
                          <SingleTestimonialTwo
                            testiImage={Url + article.image}
                            name={article.name}
                            designation={article.adjective}
                            description={article.title}
                            id={article._id}
                          />
                        ))}
                    </div>
                    <div className="col-lg-12 lg-pl-0 ml--14 md-ml-0">
                      {ArticlesItems &&
                        ArticlesItems.slice(4, 10).map((article) => (
                          <SingleTestimonialTwo
                            testiImage={Url + article.image}
                            name={article.name}
                            designation={article.adjective}
                            description={article.title}
                            id={article._id}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
