import React from 'react'
import { useDispatch } from "react-redux";
import { getAcademicProgramsList } from "../../redux/programList/actions";
import { useSelector } from "react-redux";
import { Url1 } from "../../constants/defaultValues";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useEffect } from "react";
import CourseSingleSix from "../../components/Courses/CourseSingleSix";

const CoursePart = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAcademicProgramsList());
  }, []);
  const { allProgramsItems } = useSelector((state) => state.programsListApp);
  const high =
    allProgramsItems &&
    allProgramsItems.filter(
      (pro) =>
        pro.proCertificate === "دكتوراه" || pro.proCertificate === "ماجيستير"
    );
  const low =
    allProgramsItems &&
    allProgramsItems.filter(
      (pro) =>
        pro.proCertificate !== "دكتوراه" || pro.proCertificate !== "ماجيستير"
    );
  let tab1 = "الكل",
    tab2 = "الدراسات العليا",
    tab3 = "الدراسات الجامعية";

  const tabStyle = "gridFilter text-center mb-50 md-mb-30";
  return (
    <div className="rs-latest-couses orange-style pt-100 pb-100 md-pt-80 md-pb-80 rtl">
      <div className="container">
        <Tabs>
          <TabList className={tabStyle}>
            <Tab>
              <button>{tab1}</button>
            </Tab>
            <Tab>
              <button>{tab2}</button>
            </Tab>
            <Tab>
              <button>{tab3}</button>
            </Tab>
          </TabList>

          <TabPanel>
            <div className="row">
              {allProgramsItems &&
                allProgramsItems.map((program) => (
                  <div className="col-lg-6 mb-40" key={program._id}>
                    <CourseSingleSix
                      courseImg={`${Url1}${program.headImage}`}
                      proUrl={`/acprograms/${program._id}`}
                      courseTitle={program.title}
                      userCount={program.faculty}
                      department={program.department}
                      btnText="تفاصيل اكثر"
                      proCertificate={program.proCertificate}
                    />
                  </div>
                ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row">
              {high &&
                high.map((program) => (
                  <div className="col-lg-6 mb-40" key={program._id}>
                    <CourseSingleSix
                      courseImg={`${Url1}${program.headImage}`}
                      proUrl={`/acprograms/${program._id}`}
                      courseTitle={program.title}
                      userCount={program.faculty}
                      department={program.department}
                      btnText="تفاصيل اكثر"
                      proCertificate={program.proCertificate}
                    />
                  </div>
                ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row">
              {low &&
                low.map((program) => (
                  <div className="col-lg-6 mb-40" key={program._id}>
                    <CourseSingleSix
                      courseImg={`${Url1}${program.headImage}`}
                      courseTitle={program.title}
                      proUrl={`/acprograms/${program._id}`}
                      department={program.department}
                      userCount={program.faculty}
                      btnText="تفاصيل اكثر"
                      proCertificate={program.proCertificate}
                    />
                  </div>
                ))}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default CoursePart;
