import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import SectionTitle from "../../components/Common/SectionTitle";

const Cta = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="rs-cta style2">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="YLN1Argi7ik"
        onClose={() => {
          openModal();
        }}
      />
      <div className="partition-bg-wrap home2">
        <div className="container">
          <div className="row y-bottom">
            <div
              className="col-lg-6 pl-62 pt-134 pb-150 md-pl-14 md-pt-44 md-pb-50"
              style={{ direction: "rtl" }}
            >
              <SectionTitle
                sectionClass="sec-title mb-40 md-mb-20"
                titleClass="title mb-16 md-mb-10 "
                title="الخطة التشغيلية للجامعة (2022-2023م)"
                descClass="desc text-justify text-white"
                description="في اطار تقديم خدمات تعليمية متطورة، والرفع من مستوى البحث العلمي واستثماره، والمساهمة الفاعلة في تنمية المجتمع وانطلاقا من رؤية ورسالة الجامعة لرسم خارطة طريق للمستقبل فقد بداءات الجامعة مشروع الخطة الاستراتيجية (2019-2023م) حيث وضعت عدة اهداف استراتيجية وينبثق عن كل هدف استراتيجي مجموعة من الاهداف التشغيلية لتحقيقه وبناء على ذلك تم اعداد خطة تشغيلية للجامعة ( 2022-2023م ) بحيث تتظمن جميع الانشطة التي تحقق الاهداف التشغيلية والفترة الزمنية لكل نشاط والمؤشرات اللازمة لقياس النشاطات وكذلك الجهات التابعة للجامعة والمناطة بتنفيذ كل نشاط."
              />
              <div className="btn-part">
                <a className="readon2" href="/w-plan.pdf">
                  المزيد
                </a>
              </div>
            </div>
            <div
              className="col-lg-6 pl-62 pt-134 pb-150 md-pl-14 md-pt-44 md-pb-50"
              style={{ direction: "rtl" }}
            >
              <SectionTitle
                sectionClass="sec-title mb-40 md-mb-20"
                titleClass="title mb-16 md-mb-10"
                title="الخطة الاستراتيجية للجامعة"
                descClass="desc text-justify"
                description="تتطلع جامعة الزاوية لان تصبح منارة علمية رائدة تساهم في احداث تنمية مستدامة في المنطقة الغربية بشكل خاص، وفي كل ارجاء البلاد بشكل عام. وتعمل الجامعة على رفع مستواها اقليميا ودوليا بالتركيز على السمعة الاكاديمية والبحث العلمي المتميز وانفتاح الجامعة على محيطها والشراكات المحليو والدولية. بحيث تتمحور رسالتها حول ثلاثة عناصر رئيسة هي: تقديم خدمات تعليمية متطورة، والرفع من مستوى البحث العلمي واستثماره، والمساهمة الفاعلة في تنمية المجتمع.
                "
              />
              <div className="btn-part">
                <a className="readon2" href="/plan.pdf">
                  المزيد
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
