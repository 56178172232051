import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import SectionTitle from "../../components/Common/SectionTitle";

const AboutVideo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="rs-cta style2">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="FMvA5fyZ338"
        onClose={() => {
          openModal();
        }}
      />
      <div className="partition-bg-wrap inner-page">
        <div className="container">
          <div className="row y-bottom">
            <div className="col-lg-6 pb-50 md-pt-100 md-pb-100 mb-80">
              {/* <div className="video-wrap ">
                <a
                  className="popup-videos "
                  onClick={() => {
                    openModal();
                  }}
                >
                  <i className="fa fa-play"></i>{" "}
                  <h4 className="title mb-0 text-right">كلمة رئيس الجامعة</h4>
                </a>
              </div> */}
            </div>
            <div className="col-lg-6 pl-62 pt-60 pb-30 md-pl-14 md-pt-0 md-pb-70 ">
              <SectionTitle
                sectionClass="sec-title mb-40"
                titleClass="title mb-16"
                title="University President's Speech "
                descClass="desc"
                description="  Praise be to God, Lord of the Worlds, and may peace and blessings be upon the noblest of prophets and messengers, our master Muhammad, and upon his family and companions.  
                <br />         I sincerely thank all visitors to the University of Zawia's website and hope it offers you detailed and vast information about our esteemed institution.      
                <br /> Our university is committed to continuously supporting both our current students and alumni, inspiring them to embrace change and strive for excellence within their communities. We aim to instil in them the highest standards of quality, empowering them to become leaders who contribute to providing better services for our nation and society.        
                I would like to take a moment to commend those involved in our graduate studies program. They are making significant strides toward achieving the excellence we aspire to, moving forward with determination despite the challenges our university faces. Their dedication and hard work will be etched in the history of our institution. 
               I also wish to express my pride in our university staff for their commitment to performing their duties with diligence and dedication.                                                                                                                    Nor I forget the noble mission carried out by our esteemed faculty members. I extend to them my highest regards and heartfelt appreciation. Together, we aspire to elevate our university to be among the leading institutions, both nationally and internationally.                                              
                      <br />  
Thank you, peace be upon you and the mercy of Allah and His blessings. 
                       <br /> 
                       President of the University
                 <br />
                 
                
                 Prof. Dr. Essam Al-Badri Abu Khader 
                 <br />
                <a href='mailto:president@zu.edu.ly '> president@zu.edu.ly</a>
            "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutVideo;
