import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAdvsList } from "../../redux/advsList/actions";
import { useSelector } from "react-redux";

const RecentAdvsWidget = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdvsList());
  }, []);
  const { advsItems } = useSelector((state) => state.advsListApp);
  const advs = advsItems && advsItems.filter((adv) => adv.show);
  return (
    <div className="recent-posts mb-50 rtl">
      <h3 className="widget-title">اهم الاعلانات</h3>
      <ul>
        {advs &&
          advs.slice(0, 6).map((adv) => (
            <li className="text-justify">
              <Link to={`/advs/${adv._id}`}>{adv.title} </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default RecentAdvsWidget;
