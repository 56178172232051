// eslint-disable-next-line import/no-cycle
import {
  ADVS_LIST_GET_LIST,
  ADVS_LIST_GET_LIST_SUCCESS,
  ADVS_LIST_GET_LIST_ERROR,
  ADVS_LIST_GET_IMPORTANT,
  ADVS_LIST_GET_IMPORTANT_SUCCESS,
  ADVS_LIST_GET_IMPORTANT_ERROR,
  ADVS_LIST_GET_LIST_BY_TRACKING,
  ADVS_LIST_GET_LIST_BY_TRACKING_SUCCESS,
  ADVS_LIST_GET_LIST_BY_TRACKING_ERROR,
  ADVS_LIST_GET_ONE,
  ADVS_LIST_GET_ONE_SUCCESS,
  ADVS_LIST_GET_ONE_ERROR,
  ADVS_LIST_GET_LIST_WITH_FILTER,
  ADVS_LIST_GET_LIST_WITH_ORDER,
  ADVS_LIST_GET_LIST_SEARCH,
  ADVS_LIST_GET_TOTALS,
  ADVS_LIST_GET_TOTALS_SUCCESS,
  ADVS_LIST_GET_TOTALS_ERROR,
} from "../contants";

export const getTotals = () => ({
  type: ADVS_LIST_GET_TOTALS,
});

export const getTotalsSuccess = (item) => ({
  type: ADVS_LIST_GET_TOTALS_SUCCESS,
  payload: item,
});

export const getTotalsError = (error) => ({
  type: ADVS_LIST_GET_TOTALS_ERROR,
  payload: error,
});
export const getOneAdv = (url) => ({
  type: ADVS_LIST_GET_ONE,
  payload: url,
});

export const getOneAdvSuccess = (item) => ({
  type: ADVS_LIST_GET_ONE_SUCCESS,
  payload: item,
});

export const getOneAdvError = (error) => ({
  type: ADVS_LIST_GET_ONE_ERROR,
  payload: error,
});

export const getAdvsList = (selectedPageSize, currentPage) => ({
  type: ADVS_LIST_GET_LIST,
  payload: { selectedPageSize, currentPage },
});

export const getAdvsListSuccess = (items) => ({
  type: ADVS_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getAdvsListError = (error) => ({
  type: ADVS_LIST_GET_LIST_ERROR,
  payload: error,
});

export const getImportantAdvs = () => ({
  type: ADVS_LIST_GET_IMPORTANT,
});

export const getImportantAdvsSuccess = (items) => ({
  type: ADVS_LIST_GET_IMPORTANT_SUCCESS,
  payload: items,
});

export const getImportantAdvsError = (error) => ({
  type: ADVS_LIST_GET_IMPORTANT_ERROR,
  payload: error,
});
export const getAdvsListByTracking = (tracking) => ({
  type: ADVS_LIST_GET_LIST_BY_TRACKING,
  payload: tracking,
});

export const getAdvsListByTrackingSuccess = (items) => ({
  type: ADVS_LIST_GET_LIST_BY_TRACKING_SUCCESS,
  payload: items,
});

export const getAdvsListByTrackingError = (error) => ({
  type: ADVS_LIST_GET_LIST_BY_TRACKING_ERROR,
  payload: error,
});

export const getAdvsListWithFilter = (column, value) => ({
  type: ADVS_LIST_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getAdvsListWithOrder = (column) => ({
  type: ADVS_LIST_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getAdvsListSearch = (keyword) => ({
  type: ADVS_LIST_GET_LIST_SEARCH,
  payload: keyword,
});
