import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import { Url } from "../../constants/defaultValues";

const FaqSection = ({ faculty }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  const bgStyle = {
    backgroundImage: `url(${faculty && Url + faculty.aboutImage2})`,
    "background-size": "cover",
    "background-position": "center",
    width: "100%",
    height: "100%",
    minHeight: "615px",
  };

  return (
    <div
      className="rs-faq-part style1 orange-style pt-100 pb-100 md-pt-70 md-pb-70"
      style={{ direction: "rtl" }}
    >
      {faculty && faculty.videoLink && (
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId={faculty.videoLink}
          onClose={() => {
            openModal();
          }}
        />
      )}
      <div className="container">
        <div className="row">
          <div className="col-lg-6 padding-0">
            <div className="main-part">
              <div className="title mb-40 md-mb-14">
                <h2 className="text-part">رؤية ، رسالة و اهداف الكلية</h2>
              </div>
              <div className="faq-content">
                <Accordion className="accordion-style1" preExpanded={"a"}>
                  <AccordionItem className="accordion-item" uuid="a">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        رؤية الكلية
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body text-justify">
                      {faculty && faculty.vision}
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="b">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        رسالة الكلية
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body text-justify">
                      {faculty && faculty.message}
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="c">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        اهداف الكلية
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body text-justify">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: faculty && faculty.objectives,
                        }}
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-lg-6 padding-0">
            <div className="media-icon orange-color" style={bgStyle}>
              <a
                href="#"
                className="popup-videos"
                onClick={() => {
                  openModal();
                }}
              >
                <i className="fa fa-play"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
