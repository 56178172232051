import React from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import AdvsMain from "./AdvsMain";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import bannerbg from "../../assets/img/breadcrumbs/breadcrumbs.jpg";

const CourseOne = ({ match }) => {
  const faculty = match.params.url;

  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        faculty={faculty ? true : false}
        facultyId={faculty && faculty}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4 rtl"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="info@zu.edu.ly"
        phoneNumber="(+218) 23-762682"
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="اعلانات و نشاطات الجامعة"
        innerClass="breadcrumbs-text"
        pageName="الاعلانات"
        parentCategory={faculty ? `الرجوع للكلية` : "الرئيسية"}
        breadcrumbsImg={bannerbg}
        mainLink={faculty ? `/faculty/${faculty}` : null}
      />
      {/* breadcrumb-area-start */}

      {/* Course Main */}
      <AdvsMain faculty={faculty} />
      {/* Course Main */}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70 rtl"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default CourseOne;
