import React from "react";
import { Link } from "react-router-dom";

const BannerStyleSix = ({ title, bgImg }) => {
  const bgStyle = {
    backgroundImage: `url(${bgImg})`,
    "background-size": "cover",
    "background-position": "center",
    display: "grid",
    "align-items": "center",
  };
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div id="rs-banner" className="rs-banner style8 rtl" style={bgStyle}>
        <div className="container">
          <div className="banner-content">
            <div className="sl-sub-title">جامعة الزاوية</div>
            <h1 className="sl-title">{title}</h1>
            <div className="sl-btn">
              <Link className="readon yellow-btn" to="/about">
                إقرأ المزيد ...
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}
    </React.Fragment>
  );
};

export default BannerStyleSix;
