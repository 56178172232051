import { combineReducers } from "redux";

import facultiesListApp from "./facultiesList/reducer";
import newsListApp from "./newsList/reducer";
import advsListApp from "./advsList/reducer";
import filesListApp from "./filesList/reducer";
import departmentListApp from "./departmentList/reducer";
import speechListApp from "./speechList/reducer";
import articlesListApp from "./articlesList/reducer";
import programsListApp from "./programList/reducer";
import memberlistApp from "./membersList/reducer";
import conferencesListApp from "./ceoferenceList/reducer";

const reducers = combineReducers({
  facultiesListApp,
  advsListApp,
  newsListApp,
  filesListApp,
  departmentListApp,
  conferencesListApp,
  speechListApp,
  articlesListApp,
  programsListApp,
  memberlistApp,
});

export default reducers;
