import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { CONFERENCES_GET_LIST } from "../contants";

import { getConferncesListSuccess, getConferncesListError } from "./actions";

const getConferencesListRequest = async (tracking) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`https://officesdb.zu.edu.ly/api/conferences`);

function* getFileListItems({ payload }) {
  try {
    const response = yield call(getConferencesListRequest, payload);
    yield put(getConferncesListSuccess(response.data));
  } catch (error) {
    yield put(getConferncesListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(CONFERENCES_GET_LIST, getFileListItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
