import React from 'react';
import { Link } from 'react-router-dom';

const SingleProduct = (props) => {
    const { title, image, price, Linkto } = props;
    return (
        <React.Fragment>
            <div className="product-list">
                <div className="image-product">
                    <a target='_blank' href={Linkto ? Linkto : '/'}>
                        <img src={image} alt="blog image" />
                    </a>
                    <div className="overlay">
                        <a target='_blank' href={Linkto ? Linkto : '/'}>
                            <i className="flaticon-link"></i>
                        </a>
                    </div>

                </div>
                <div className="content-desc text-center">
                    <h2 className="product-title">
                        <a target='_blank' href={Linkto ? Linkto : '/'}>
                            {title}
                        </a>
                    </h2>
                </div>
            </div>
        </React.Fragment>

    )
}

export default SingleProduct