import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import ReactPlayer from "react-player";

import icon1 from "../../assets/img/categories/icons/1.png";

const About = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const openModal1 = () => setIsOpen(!isOpen);
  const openModal2 = () => setIsOpen1(!isOpen);
  const openModal3 = () => setIsOpen2(!isOpen);
  return (
    <div id="rs-about" className="rs-about style1 pb-100 md-pb-70 rtl">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="zuCcJ9jKa80"
        onClose={() => {
          openModal1();
        }}
      />
      <ModalVideo
        channel="youtube"
        isOpen={isOpen1}
        videoId="MQXYzUIlvUE"
        onClose={() => {
          openModal2();
        }}
      />
      <ModalVideo
        channel="youtube"
        isOpen={isOpen2}
        videoId="6D2vo17Gc2k"
        onClose={() => {
          openModal3();
        }}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-4 order-last">
            <div className="notice-bord style1">
              <h4 className="title">ملفات تهمك</h4>
              <ul>
                <li>
                  <div className="date">
                    <div className="icon-part">
                      <a href="/docs/doc1.pdf" target="_blank">
                        <img src={icon1} alt="icons" />
                      </a>{" "}
                    </div>
                  </div>
                  <div className="desc">
                    انشاء حساب طالب في النظام الموحّد للجامعات الليبية
                  </div>
                </li>
                <li>
                  <div className="date">
                    {" "}
                    <a href="/docs/doc2.pdf" target="_blank">
                      <img src={icon1} alt="icons" />
                    </a>{" "}
                  </div>
                  <div className="desc">
                    انشاء حساب معيد في النظام الموحّد للجامعات الليبية
                  </div>
                </li>
                <li>
                  <div className="date">
                    {" "}
                    <a href="/docs/doc3.pdf" target="_blank">
                      <img src={icon1} alt="icons" />
                    </a>
                  </div>
                  <div className="desc">
                    انشاء حساب موظف في النظام الموحّد للجامعات الليبية
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 pr-50 md-pr-14">
            <div className="about-part">
              <div className="sec-title mb-40">
                <div className="sub-title primary">تنبيهات</div>
                <h2 className="title">
                  السادة المحترمين : أعضاء هيئة التدريس , الموظفين , المعيدين
                </h2>
                <div className="desc text-justify">
                  سعيا من وزارة التعليم العالي والبحث العلمي لإنشاء منظومة موحدة
                  للجامعات الليبية وانطلاق مشروع التحول الرقمي الموحد . يطلب
                  منكم الدخول الي منظومة النظام الموحد للجامعات الليبية عبر
                  الرابط المرفق مع هذا الإعلان والتسجيل وانشاء حساب مع الأخذ في
                  الاعتبار الملاحظات التالية :
                  <li>
                    ان يكون لديك بريد إلكتروني لتلقي رمز الدخول عليه وانشاء رقم
                    سري بعد تأكيد الحساب.
                  </li>
                  <li>
                    تجهيز كافة البيانات اللازمة كالبيانات الشخصية طبقا لشهادة
                    الميلاد الإلكترونية أو كتيب العائلة و البيانات الاكاديمية
                    والإدارية (تاريخ التعيين والمباشرة وغيرها).
                  </li>
                  <li>تصوير صورة شخصية لأدراجها في الموقع .</li>
                </div>
              </div>
               
              <div>
                <h5>فيديو توضيح عن كيفية التسجيل عضو هيئة تدريس في المنظومة</h5>
                <ReactPlayer
                  url="https://youtu.be/zuCcJ9jKa80"
                  controls
                  width="450"
                />
              </div>
              <br />
              <div>
                <h5>فيديو توضيح عن كيفية التسجيل معيد في المنظومة</h5>
                <ReactPlayer url="./docs/m.mp4" controls width="450" />
              </div>
              <br />
              <div>
                <h5>فيديو توضيح عن كيفية التسجيل طالب في المنظومة</h5>
                <ReactPlayer url="./docs/student.mp4" controls width="450" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
