import React, { useEffect } from "react";
import Slider from "react-slick";
import Tilty from "react-tilty";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleTestimonial4 from "../../components/Testimonial/SingleTestimonial4";

import animBall from "../../assets/img/shape/circle-shape.png";
import { useDispatch, useSelector } from "react-redux";
import { getSpeechList } from "../../redux/speechList/actions";
import { Url } from "../../constants/defaultValues";

const Testimonial = ({ faculty }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    faculty && dispatch(getSpeechList(faculty));
  }, [faculty]);
  const testimonialSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const { SpeechItems } = useSelector((state) => state.speechListApp);
  return (
    <React.Fragment>
      <div className="rs-testimonial style4 pt-100 pb-100 md-pt-70 md-pb-70">
        <div className="container relative">
          <div className="left-top-shape">
            <Tilty perspective="800" reverse={true}>
              <img className="rotateme" src={animBall} alt="" />
            </Tilty>
          </div>
          <SectionTitle
            sectionClass="sec-title mb-60 md-mb-30 text-center"
            subtitleClass="sub-title primary"
            subtitle="كلمات"
            titleClass="title mb-0"
            title="أعضاء مجلس ادارة الكلية"
          />
          <Slider {...testimonialSettings}>
            {SpeechItems &&
              SpeechItems.map((speech) => (
                <SingleTestimonial4
                  itemClass="testi-item"
                  authorImg={Url + speech.image}
                  name={speech.name}
                  designation={speech.adjective}
                  description={speech.details}
                />
              ))}
          </Slider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonial;
