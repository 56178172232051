import React, { useEffect } from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import DegreeSingle from "../../components/Degree/DegreeSingle";
import { Url1 } from "../../constants/defaultValues";
import { Link } from "react-router-dom";
import loading from "../../assets/img/loading.gif";
import { useDispatch, useSelector } from "react-redux";
import { getNewsList } from "../../redux/newsList/actions";
const Blog = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNewsList(5, 1));
  }, []);
  const { newsItems } = useSelector((state) => state.newsListApp);
  const newss = newsItems && newsItems.filter((news) => news.show);
  return (
    <div
      className="rs-degree style1 modify gray-bg pt-100 pb-70 md-pt-70 md-pb-40"
      style={{ direction: "rtl" }}
    >
      <div className="container">
        <div className="row y-middle">
          <div className="col-lg-4 col-md-6 mb-30">
            {/* Section Title Start */}
            <SectionTitle
              sectionClass="sec-title"
              subtitleClass="sub-title primary"
              subtitle="الاحداث "
              titleClass="title mb-0"
              title="اخر اخبار   الجامعة"
            />
            {/* Section Title End */}
          </div>
          {newss ? (
            newss.slice(0, 5).map((news) => (
              <div className="col-lg-4 col-md-6 mb-30">
                <DegreeSingle
                  itemImage={`${Url1}${news.image}`}
                  title={news.title}
                  desc={news.description.substr(3, 90)}
                  itemLink={`post/${news._id}`}
                  itemDate={news.createdAt}
                  author={news.author && news.author.name}
                />
              </div>
            ))
          ) : (
            <div className="col-lg-4 col-md-6 mb-30">
              <img src={loading} alt="grid img" width="250" />
            </div>
          )}
          <div className="rs-latest-events style1 bg-wrap  text-center">
            <div className="event-wrap">
              <div className="btn-part mt-54 md-mt-24">
                <a  target="_blank" href="https://events.zu.edu.ly/">المزيد من الاخبار </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
