import React from 'react'
import { Link } from "react-router-dom";

const SingleTeam = (props) => {
  const { teamClass, Image, Title, Designation, Id, department } = props;
  return (
    <div className={teamClass ? teamClass : "team-item"}>
      <img src={Image} alt={Title} />
      <div className="content-part">
        <h4 className="name">
          <Link to={`/members/${Id}`}>{Title}</Link>
        </h4>
        <span className="designation">
          الصفة : {Designation ? Designation : ""}
        </span>

        <ul className="social-links">
          <li>{department ? department : ""}</li>
        </ul>
      </div>
    </div>
  );
};

export default SingleTeam;
