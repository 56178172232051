import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import SectionTitle from "../../components/Common/SectionTitle";

import gridImg1 from "../../assets/img/about/style2/grid1.jpg";
import gridImg2 from "../../assets/img/about/style2/grid2.jpg";

function About() {
  const [state, setState] = useState(true);

  const counters = [
    {
      countNum: 30000 ,
      countTitle: "Students",
      counterPrefix: "",
    },
    {
      countNum: 2900,
      countTitle: "Members",
      counterPrefix: "",
    },
    {
      countNum: 2800,
      countTitle: "Employed",
      counterPrefix: "",
    },
  ];
  return (
    <div
      id="rs-about"
      className="rs-about style2 pt-94 pb-100 md-pt-64 md-pb-70"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5 pr-64 md-pr-14 md-mb-50">
            <div className="about-intro text-justify">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title mb-40"
                subtitleClass="sub-title primary"
                subtitle="About the University"
                titleClass="title mb-22 white-color"
                // title="اهلا بكم بالموقع الالكتروني"
                descClass="desc big white-color"
                description="
                The University of Zawia is a Libyan state university established in 1988 by the former General People's Committee No. [135]. It is a member of many communities such as the Association of Arab Universities (AARU), the Association of African Universities (AAU) and the Federation of Universities of Islamic World. The University of Zawia has 29 faculties located in many cities (Zawia, Ejelat and Zuwara), these faculties are of different fields of specialization; Arts, Education, Law, Economics, Science, Veterinary, Agriculture, Engineering, Medicine, Pharmacy, Medical Technology, and Information Technology. The university administration and its faculties are located 6 km south of Zawia city in an area of about 100 square hectares. The complex buildings are modern and aesthetically designed from inside and outside. The total teaching staff is about 2400, and the number of administrative staff and technicians is about 2700.
              " 
              />
              {/* Section Title End */}
            </div>
          </div>
          <div className="col-lg-7 ml--40 md-ml-0">
            {counters && (
              <div className="row rs-counter couter-area mb-40">
                {counters
                  .map((counter, num) => (
                    <div key={num} className="col-md-4">
                      <div className="counter-item one">
                        <h2 className="number rs-count">
                          <CountUp
                            start={state ? 0 : counter.countNum}
                            end={counter.countNum}
                            duration={10}
                            onEnd={() => setState(false)}
                          />
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                          <span className="counter-prefix">
                            {counter.counterPrefix}
                          </span>
                        </h2>
                        <h4 className="title mb-0">{counter.countTitle}</h4>
                      </div>
                    </div>
                  ))
                  .slice(0, 1)}
                {counters
                  .map((counter, num) => (
                    <div key={num} className="col-md-4">
                      <div className="counter-item two">
                        <h2 className="number rs-count">
                          <CountUp
                            start={state ? 0 : counter.countNum}
                            end={counter.countNum}
                            duration={10}
                            onEnd={() => setState(false)}
                          />
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                          <span className="counter-prefix">
                            {counter.counterPrefix}
                          </span>
                        </h2>
                        <h4 className="title mb-0">{counter.countTitle}</h4>
                      </div>
                    </div>
                  ))
                  .slice(1, 2)}
                {counters
                  .map((counter, num) => (
                    <div key={num} className="col-md-4">
                      <div className="counter-item three">
                        <h2 className="number rs-count">
                          <CountUp
                            start={state ? 0 : counter.countNum}
                            end={counter.countNum}
                            duration={10}
                            onEnd={() => setState(false)}
                          />
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                          <span className="counter-prefix">
                            {counter.counterPrefix}
                          </span>
                        </h2>
                        <h4 className="title mb-0">{counter.countTitle}</h4>
                      </div>
                    </div>
                  ))
                  .slice(2, 3)}
              </div>
            )}
            <div className="row grid-area">
              <div className="col-md-6 sm-mb-30">
                <div className="image-grid">
                  <img src={gridImg1} alt="grid img" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="image-grid">
                  <img src={gridImg2} alt="grid img" />
                </div>
              </div>
            </div>
            <div className="sec-title pt-70">
               <h2 className=" sub-title primary ">University vision </h2>
               <div className="desc big text-justify">Achieving excellence and leadership in the field of education, scientific research, and community servic
               </div>
               <h2 className=" sub-title primary ">University mission </h2>
               <div className="desc big text-justify">To provide outstanding educational and research services in serving the community through qualified staff members, scientific programs, a motivational environment, effective partnerships with local and international institutions, and meeting the needs of labour market and quality standards and accreditation.
</div>
<h2 className=" sub-title primary "> University goals </h2>
               <div className="desc big text-justify">
                <ol>
                  <li>providing educational programs in various fields according to local and international quality standards.</li>
                  <li>Elevating the level of scientific research.</li>
                  <li>Enhancing the university's role in community service.</li>
                  <li>Providing an appropriate environment and improving the level of services.</li>
                  <li>Implementing ongoing policies and programs for the qualification and development of the university's human resources.</li>
                  <li>Aligning university outputs with labour market requirements.</li>
                  <li>Supporting and activating cooperation between the university and local, regional, and international institutions.</li>
                </ol>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
