import React from "react";
import Slider from "react-slick";
import SinglePostThree from "./SinglePostThree";
import moment from "moment";
import { Url1 } from "../../constants/defaultValues";

const BlogPartTwo = ({ newsItems }) => {
  const blogSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Slider {...blogSettings}>
        {newsItems &&
          newsItems
            .slice(0, 10)
            .map((news) => (
              <SinglePostThree
                blogClass="blog-item"
                contentClass="blog-content new-style"
                blogImage={`${Url1}${news.image}`}
                blogTitle={news.title}
                blogDesc={news.description}
                blogPublishedDate={moment(news.updatedAt).format("ll")}
                blogAuthor={news.author.name}
                Id={news._id}
                cmtQnty="15"
              />
            ))}
      </Slider>
    </React.Fragment>
  );
};

export default BlogPartTwo;
