import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { Url } from "../../constants/defaultValues";

import {
  DEPARTMENTS_LIST_GET_LIST,
  DEPARTMENTS_LIST_GET_ONE,
} from "../contants";

import {
  getDepartmentsListSuccess,
  getDepartmentsListError,
  getDepartmentDetailsSuccess,
  getDepartmentDetailsError,
} from "./actions";

const getFacultyDetailsItemsRequest = async (url) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/departments/${url}`);

function* getFacultyDetailsItems({ payload }) {
  try {
    const response = yield call(getFacultyDetailsItemsRequest, payload);
    yield put(getDepartmentDetailsSuccess(response.data));
  } catch (error) {
    yield put(getDepartmentDetailsError(error));
  }
}
const getDepartmentsListRequest = async (url) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/departments/byfaculty/${url}`);

function* getDepartmentsListItems({ payload }) {
  try {
    const response = yield call(getDepartmentsListRequest, payload);
    yield put(getDepartmentsListSuccess(response.data));
  } catch (error) {
    yield put(getDepartmentsListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(DEPARTMENTS_LIST_GET_LIST, getDepartmentsListItems);
}

export function* watchGetOne() {
  yield takeEvery(DEPARTMENTS_LIST_GET_ONE, getFacultyDetailsItems);
}
export default function* rootSaga() {
  yield all([fork(watchGetList), fork(watchGetOne)]);
}
