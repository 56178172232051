import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import SectionTitle from "../../components/Common/SectionTitle";

const AboutVideo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="rs-cta style2">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="FMvA5fyZ338"
        onClose={() => {
          openModal();
        }}
      />
      <div className="partition-bg-wrap inner-page">
        <div className="container">
          <div className="row y-bottom">
            <div className="col-lg-6 pb-50 md-pt-100 md-pb-100 mb-80">
              {/* <div className="video-wrap ">
                <a
                  className="popup-videos "
                  onClick={() => {
                    openModal();
                  }}
                >
                  <i className="fa fa-play"></i>{" "}
                  <h4 className="title mb-0 text-right">كلمة رئيس الجامعة</h4>
                </a>
              </div> */}
            </div>
            <div className="col-lg-6 pl-62 pt-60 pb-30 md-pl-14 md-pt-0 md-pb-70 rtl">
              <SectionTitle
                sectionClass="sec-title mb-40"
                titleClass="title mb-16"
                title="كلمة رئيس الجامعة"
                descClass="desc"
                description="  الحمد لله رب العالمين، والصلاة والسلام على أشرف الأنبياء والمرسلين، سيدنا محمد وعلى آله وصحبه أجمعين.
                <br />
                فإنني من خلال منبر جامعة الزاوية الإلكتروني، أقدم عظيم شكري وامتناني، لكل زوارنا عبر هذا الموقع الخاص بالجامعة.
                هذا و أتمنى حقا أن تقدم لكم هذه المنصة وفرة من المعلومات حول هذه الجامعة العريقة.<br />
                إن سعي الجامعة الدائم و المتواصل لدعم الطلاب الحاليين، و الخرجين، ليكونوا متحفزين نحو التغيير و التميز في مجتمعاتهم، بالإضافة إلى غرس معايير الجودة فيهم كي يكونوا قادة ليسهموا في تقديم خدمات أفضل لوطنهم ومجتمعهم.<br />
                كما يطيب لي أن أحيي القائمين على الدراسات العليا بالجامعة، الذين يشقون طريقهم نحو التميز المنشود بخطى ثابتة، رغم الصعوبات والتحديات التي تمر بها الجامعة، وبالرغم من هذا فإنها تقدم مجهودا ساميا سيسطر في تاريخ الجامعة.
                وإنني أقدم اعتزازي لموظفي الجامعة على حسن قيامهم بأعمالهم المنوطة إليهم بكل جد واجتهاد.<br />
                ولا أنسى هذه المهمة النبيلة التي يقوم بها نخبة من الأساتذة الأفاضل، أن أقدم لهم أرقى تحياتي و أسمى عبارات الحب و التقدير.<br />
            متمنين أن تصبح الجامعة من الجامعات المتقدمة، ومن أفضل الجامعات بالداخل والخارج.
                           والسلام عليكم ورحمة الله وبركاته
                       <br />  
                       <br /> 
                 رئيس جامعة الزاوية 
                 <br />
                 
                
                 ا.د. عصام البدري ابوخضير
                <a href='mailto:president@zu.edu.ly '> president@zu.edu.ly</a>
            "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutVideo;
