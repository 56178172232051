import React from 'react';
import Slider from "react-slick";

import partnerLogo1 from '../../assets/img/brand/style2/1.png';
import partnerLogo2 from '../../assets/img/brand/style2/2.png';
import partnerLogo3 from '../../assets/img/brand/style2/3.png';
import partnerLogo4 from '../../assets/img/brand/style2/4.png';
import SectionTitle from '../../components/Common/SectionTitle';

const BrandHomeFive = () => {

    const sliderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="rs-partner pb-100 md-pb-70">
            <div className="container">
            <SectionTitle
              sectionClass="sec-title mb-60 md-mb-30 text-center"
              subtitleClass="sub-title primary"
              titleClass="title mb-0"
              title="University rankings"
            />
                <Slider {...sliderSettings}>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a href="https://www.timeshighereducation.com/world-university-rankings/university-zawia
">
                                <img src={partnerLogo1} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a href="https://www.uniranks.com/ranking?country=Libya">
                                <img src={partnerLogo3} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a href="https://auranking.com/Ranking?id=10&country=%D9%84%D9%8A%D8%A8%D9%8A%D8%A7&univ=%D8%A7%D9%84%D8%B2%D8%A7%D9%88%D9%8A%D8%A9&sort=0">
                                <img src={partnerLogo2} alt="" />
                            </a>
                        </div>
                    </div>
                  
                    <div className="partner-item">
                        <div className="logo-img">
                            <a href="https://www.topuniversities.com/universities/university-zawia">
                                <img src={partnerLogo4} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a href="https://auranking.com/Ranking?id=10&country=%D9%84%D9%8A%D8%A8%D9%8A%D8%A7&univ=%D8%A7%D9%84%D8%B2%D8%A7%D9%88%D9%8A%D8%A9&sort=0">
                                <img src={partnerLogo2} alt="" />
                            </a>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default BrandHomeFive;