import React from "react";
import CourseSidebar from "./CourseSidebar";
import CourseDetailsTab from "./CourseDetailsTab";
import { useSelector } from "react-redux";

const CourseDetailsPart = () => {
  const { departmentDetails } = useSelector((state) => state.departmentListApp);

  return (
    <React.Fragment>
      <div className="intro-section gray-bg pt-94 pb-100 md-pt-80 md-pb-80 loaded">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-8 md-mb-50">
              <CourseDetailsTab {...departmentDetails} />
            </div>
            <div className="video-column col-lg-4">
              <CourseSidebar {...departmentDetails} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseDetailsPart;
