import React from "react";
import Slider from "react-slick";
import SinglePostThree from "./SinglePostThree";
import { Url } from "../../constants/defaultValues";
import moment from "moment";

const BlogPartFive = ({ news }) => {
  const blogSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    rtl: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Slider {...blogSettings}>
        {news &&
          news.map((n) => (
            <SinglePostThree
              blogClass="blog-item"
              contentClass="blog-content primary-border new-style2"
              blogImage={Url + n.image}
              blogAuthor={n && n.author.name}
              blogTitle={n.title}
              blogDesc={n.description}
              Id={n._id}
              blogPublishedDate={moment(n.updatedAt).format("ll")}
              cmtQnty="15"
            />
          ))}
      </Slider>
    </React.Fragment>
  );
};

export default BlogPartFive;
