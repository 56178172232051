import React, { useEffect } from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import CourseDetailsMain from "./CourseDetailsMain";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import { Url } from "../../constants/defaultValues";
import { useDispatch, useSelector } from "react-redux";
import { getAcademicProgramItem } from "../../redux/programList/actions";
import { getFilesList } from "../../redux/filesList/actions";

const Department = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAcademicProgramItem(match.params.id));
    dispatch(getFilesList(match.params.id));
  }, [match]);
  const { academicProgramDetails } = useSelector(
    (state) => state.programsListApp
  );

  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        faculty={true}
        facultyId={academicProgramDetails && academicProgramDetails.facultyId}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4 rtl"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle={academicProgramDetails && academicProgramDetails.title}
        pageName={academicProgramDetails && academicProgramDetails.title}
        breadcrumbsImg={
          academicProgramDetails && Url + academicProgramDetails.headImage
        }
        parentCategory={`الرجوع للكلية`}
        mainLink={
          academicProgramDetails && academicProgramDetails.facultyId
            ? `/faculties/${academicProgramDetails.facultyId}`
            : null
        }
      />
      {/* breadcrumb-area-start */}

      {/* Course Details Main */}
      <CourseDetailsMain />
      {/* Course Details Main */}

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default Department;
