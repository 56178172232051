// eslint-disable-next-line import/no-cycle
import {
  FILES_GET_LIST,
  FILES_GET_LIST_SUCCESS,
  FILES_GET_LIST_ERROR,
} from "../contants";

export const getFilesList = (tracking) => ({
  type: FILES_GET_LIST,
  payload: tracking,
});

export const getFilesListSuccess = (items) => ({
  type: FILES_GET_LIST_SUCCESS,
  payload: items,
});

export const getFilesListError = (error) => ({
  type: FILES_GET_LIST_ERROR,
  payload: error,
});
