import React from "react";
import { Link } from "react-router-dom";

import aboutImg from "../../assets/img/about/home9/about.png";

const About = () => {
  return (
    <div
      id="rs-about"
      className="rs-about style9 pt-100 pb-100 md-pt-70 md-pb-80 rtl"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 md-mb-40">
            <div className="img-part">
              <img src={aboutImg} alt="Image" />
            </div>
          </div>
          <div className="col-lg-6 pl-100 md-pl-14 col-md-12">
            <div className="content">
              <div className="sub-title mb-20">عن مركز</div>
              <h2 className="sl-title mb-40 md-mb-20">
                المعلومات و التوثيق بجامعة الزاوية
              </h2>
              <p className="desc mb-50 text-justify">
                تعد مراكز المعلومات والتوثيق في جميع المنظمات الإنتاجية والخدمية
                مصدراً هاماً للمعلومات لجميع الأطراف ذات العلاقة بتلك المنظمات ،
                سواءً من داخلها أو خارجها ، وتحتل مراكز المعلومات والتوثيق في
                الجامعات أهمية بالغة لما لها من دور في المشاركة عند وضع الخطط
                الكفيلة بسد إحتياجات المجتمعات من التخصصات المختلفة التى يتطلبها
                سوق العمل في تلك المجتمعات ، كما أنها تعمل على حفظ وتوثيق
                الإحصائيات والمعلومات بشكل منظم يكفل سهولة الحصول عليها عند
                الحاجة إليها كصنع القرارات مثلاً ، كما أنها تعمل على المساعدة في
                بيان الإجراءات المتعلقة بمختلف الخدمات التى تقدمها تلك الجامعات
                . ونحن في مركز المعلومات والتوثيق بجامعة النجم الساطع نسعى قدماً
                للمساهمة في تحقيق رسالة ورؤية وأهداف الجامعة ، من خلال وضع رسالة
                للمركز تعمل على تحقيق أهداف الجامعة القائمة على تقديم أفضل
                الخدمات للمجتمع الجامعى والخارجى بما تتطلبه معايير الجودة في
                مؤسسات التعليم العالي .
              </p>
            </div>
            <div className="btn-part">
              <Link className="readon yellow-btn" to="/about">
                إقرأ اكثر
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
