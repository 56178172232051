// eslint-disable-next-line import/no-cycle
import {
  DEPARTMENTS_LIST_GET_LIST,
  DEPARTMENTS_LIST_GET_LIST_SUCCESS,
  DEPARTMENTS_LIST_GET_LIST_ERROR,
  DEPARTMENTS_LIST_GET_ONE,
  DEPARTMENTS_LIST_GET_ONE_SUCCESS,
  DEPARTMENTS_LIST_GET_ONE_ERROR,
  DEPARTMENTS_LIST_GET_LIST_SEARCH,
} from "../contants";

export const getDepartmentDetails = (url) => ({
  type: DEPARTMENTS_LIST_GET_ONE,
  payload: url,
});

export const getDepartmentDetailsSuccess = (item) => ({
  type: DEPARTMENTS_LIST_GET_ONE_SUCCESS,
  payload: item,
});

export const getDepartmentDetailsError = (error) => ({
  type: DEPARTMENTS_LIST_GET_ONE_ERROR,
  payload: error,
});

export const getDepartmentsList = (url) => ({
  type: DEPARTMENTS_LIST_GET_LIST,
  payload: url,
});

export const getDepartmentsListSuccess = (items) => ({
  type: DEPARTMENTS_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getDepartmentsListError = (error) => ({
  type: DEPARTMENTS_LIST_GET_LIST_ERROR,
  payload: error,
});

export const getDepartmentListSearch = (keyword) => ({
  type: DEPARTMENTS_LIST_GET_LIST_SEARCH,
  payload: keyword,
});
