// eslint-disable-next-line import/no-cycle
import {
  SPEECH_GET_LIST,
  SPEECH_GET_LIST_SUCCESS,
  SPEECH_GET_LIST_ERROR,
} from "../contants";

export const getSpeechList = (tracking) => ({
  type: SPEECH_GET_LIST,
  payload: tracking,
});

export const getSpeechListSuccess = (items) => ({
  type: SPEECH_GET_LIST_SUCCESS,
  payload: items,
});

export const getSpeechListError = (error) => ({
  type: SPEECH_GET_LIST_ERROR,
  payload: error,
});
