import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  MEMBERS_LIST_GET_LIST,
  MEMBERS_LIST_GET_LIST_BY_FACULTY,
  MEMBERS_LIST_GET_ONE,
} from "../contants";

import {
  getMembersListSuccess,
  getMembersListError,
  getOneMemberSuccess,
  getOneMemberError,
} from "./actions";
import { portalUrl } from "../../constants/defaultValues";

const getMembersListRequest = async (payload) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${portalUrl}api/admins/depart/${payload}`);

function* getMembersListItems({ payload }) {
  console.log(payload);
  try {
    const response = yield call(getMembersListRequest, payload);
    yield put(getMembersListSuccess(response));
  } catch (error) {
    yield put(getMembersListError(error));
  }
}

const getMembersListItemsByFacultyRequest = async (payload) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${portalUrl}api/admins/${payload}`);

function* getMembersListItemsByFaculty({ payload }) {
  console.log(payload);
  try {
    const response = yield call(getMembersListItemsByFacultyRequest, payload);
    yield put(getMembersListSuccess(response));
  } catch (error) {
    yield put(getMembersListError(error));
  }
}

const getOneMemberRequest = async (payload) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${portalUrl}api/admins/member/${payload}`);

function* getOneMemberItem({ payload }) {
  console.log(payload);
  try {
    const response = yield call(getOneMemberRequest, payload);
    yield put(getOneMemberSuccess(response));
  } catch (error) {
    yield put(getOneMemberError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(MEMBERS_LIST_GET_LIST, getMembersListItems);
}

export function* watchGetListByFaculty() {
  yield takeEvery(
    MEMBERS_LIST_GET_LIST_BY_FACULTY,
    getMembersListItemsByFaculty
  );
}

export function* watchGetOne() {
  yield takeEvery(MEMBERS_LIST_GET_ONE, getOneMemberItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetListByFaculty),
  ]);
}
