/* eslint-disable no-underscore-dangle */
import {
  CONFERENCES_GET_LIST,
  CONFERENCES_GET_LIST_SUCCESS,
  CONFERENCES_GET_LIST_ERROR,
} from "../contants";

const INIT_STATE = {
  allConferencesItems: null,
  conferencesItems: null,
  error: "",
  loading: false,
  selectedItems: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONFERENCES_GET_LIST:
      return { ...state, loading: false };

    case CONFERENCES_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allConferencesItems: action.payload,
        conferencesItems: action.payload,
      };

    case CONFERENCES_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
