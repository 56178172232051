import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { getNewsList } from "../../redux/newsList/actions";
import { Url } from "../../constants/defaultValues";

const RecentPost = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNewsList());
  }, []);
  const { newsItems } = useSelector((state) => state.newsListApp);
  return (
    <div className="recent-posts-widget mb-50">
      <h3 className="widget-title text-right">اخر الاخبار</h3>
      {newsItems &&
        newsItems.slice(0, 5).map((news) => (
          <div className="show-featured rtl">
            <div className="post-img">
              <Link to={`/news/${news._id}`}>
                <img src={Url + news.image} alt={news.title} />
              </Link>
            </div>
            <div className="post-desc">
              <Link to={`/news/${news._id}`} className="text-justify">
                {news.title}
              </Link>
              <span className="date">
                <i className="fa fa-calendar"></i>
                {moment(news.updatedAt).format("ll")}
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default RecentPost;
