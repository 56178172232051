import React from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import TeamSingleMain from "./TeamSingleMain";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";
import load from "../../assets/img/loading1.gif";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getOneMember } from "../../redux/membersList/actions";
import { useEffect } from "react";

const TeamSingle = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOneMember(match.params.id));
  }, []);
  const { member } = useSelector((state) => state.memberlistApp);
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="team"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4 rtl"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
      />
      {member ? (
        <>
          {/* breadcrumb-area-start */}
          <SiteBreadcrumb
            pageTitle={member && member.name}
            pageName={member && member.name}
            breadcrumbsImg={bannerbg}
          />
          {/* breadcrumb-area-End */}
          {/* TeamDetails Start */}
          <TeamSingleMain member={member} />
          {/* TeamDetails End */}s
        </>
      ) : (
        <div className="text-center ">
          <img src={load} alt="grid img" width="300" />
        </div>
      )}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default TeamSingle;
