import React, { useEffect } from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import load from "../../assets/img/loading1.gif";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import CourseDetailsMain from "./CourseDetailsMain";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import { Url } from "../../constants/defaultValues";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartmentDetails,
  getDepartmentsList,
} from "../../redux/departmentList/actions";
import { getAcademicProgramsListByTracking } from "../../redux/programList/actions";

const Department = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartmentDetails(match.params.url));
    dispatch(getAcademicProgramsListByTracking(match.params.url));
  }, [match]);
  const { departmentDetails, loading } = useSelector(
    (state) => state.departmentListApp
  );
  useEffect(() => {
    departmentDetails &&
      dispatch(getDepartmentsList(departmentDetails.tracking));
  }, [departmentDetails]);
  const { departmentsItems } = useSelector((state) => state.departmentListApp);

  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        {...departmentDetails}
        faculty={true}
        facultyId={departmentDetails && departmentDetails.tracking}
        departmentsItems={departmentsItems}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4 rtl"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {loading ? (
        <>
          {/* breadcrumb-area-start */}
          <SiteBreadcrumb
            pageTitle={departmentDetails && departmentDetails.title}
            pageName={departmentDetails && departmentDetails.title}
            breadcrumbsImg={
              departmentDetails && Url + departmentDetails.headImage
            }
            parentCategory={`الرجوع للكلية`}
            mainLink={
              departmentDetails && departmentDetails.tracking
                ? `/faculties/${departmentDetails.tracking}`
                : null
            }
          />
          {/* breadcrumb-area-start */}

          {/* Course Details Main */}
          <CourseDetailsMain departmentDetails={departmentDetails} />
          {/* Course Details Main */}
        </>
      ) : (
        <div className="text-center ">
          <img src={load} alt="grid img" width="300" />
        </div>
      )}
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default Department;
