import React from "react";
import { Link } from "react-router-dom";
import moment from "moment"

const DegreeSingle = (props) => {
  const { itemClass, titleClass, titleClass2, descClass, btnText, itemLink,itemDate,author } =
    props;

  return (
    <div className={itemClass ? itemClass : "degree-wrap"}>
      <img src={props.itemImage} alt={props.title} />
      <div className="title-part">
        
        <h4 className={titleClass ? titleClass : "title"}>{props.title}</h4>
       
      </div>
      <div className="content-part">
        <h4 className={titleClass2 ? titleClass2 : "title"}>
          <a  target='_blank'  href={`https://events.zu.edu.ly/${itemLink}`}>{props.title}</a>
         
        </h4>
        <small>{moment(itemDate).format("LL")} - {author}</small>
       
        <p
          className={descClass ? descClass : "desc"}
          dangerouslySetInnerHTML={{ __html: props.desc }}
        ></p>
        <div className="btn-part">
          <a  target='_blank' href={`https://events.zu.edu.ly/${itemLink}`}> {btnText ? btnText : "اقرأ المزيد"}</a>
        </div>
      </div>
    </div>
  );
};

export default DegreeSingle;
