import React, { useEffect } from "react";
import SliderStyleTwo from "../../components/Slider/SliderStyleTwoEn";
import Service from "./ServiceSection";
import About from "./AboutSection";
import Speach from "./SpeachSection";
import Cta from "./CtaSection";
import Articles from "./ArticlesSection";
import News from "./NewsSection";
import Events from "./EventsSection";
import Brand from './BrandSection';
import Publications from './PublicationSection';
import ScrollToTop from "../../components/Common/ScrollTop";
import { useDispatch, useSelector } from "react-redux";
import { getAdvsList, getImportantAdvs } from "../../redux/advsList/actions";

const HomeThreeMain = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdvsList(5, 1));
    dispatch(getImportantAdvs());
  }, []);
  const { advsItems, importantAdvsItems } = useSelector(
    (state) => state.advsListApp
  );
  const impor = importantAdvsItems && importantAdvsItems.filter( d => d.createdAt > new Date() -10  )
   console.log('impor' ,impor)

  return (
    <div className="home-style2">
      {/* SliderStyleTwo-start */}
      <SliderStyleTwo items={impor} />
      {/* SliderStyleTwo-start */}

      {/* Service-area-start */}
      <Service />
      {/* Service-area-end */}

      {/* About-area-start */}
      <About />
      {/* About-area-end */}

      <Speach />

        {/* Publication-start */}
        <Publications />
			{/* Publication-start */}
      {/* Cta-area-start */}
      <Cta />
      {/* Cta-area-end */}

      {/* Cta-area-start */}
      <Events />
      {/* Cta-area-end */}

      {/* Brand-area-start */}
      {/* <Brand /> */}
      {/* Brand-area-end */}

      {/* News-area-start */}
      <News />
      {/* News-area-end */}
       
       
     

			{/* Brand-start */}
			<Brand />
			{/* Brand-start */}

      {/* Articles-area-start */}
      {/* <Articles /> */}
      {/* Articles-area-end */}
      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </div>
  );
};

export default HomeThreeMain;
