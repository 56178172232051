export const Url = "https://db.zu.edu.ly/";
// export const Url = "http://zu.outboxteam.com/";
// export const Url = "http://localhost:4040/";

export const portalUrl = "https://resumption.zu.edu.ly/";
// export const portalUrl = "http://localhost:4030/";

// export const Url1 = "http://zu.outboxteam.com/";
export const Url1 = "https://db.zu.edu.ly/";
export const shareUrl = "https://zu.edu.ly/";
