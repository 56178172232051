import React from "react";
import NewsContent from "../../components/Widget/NewsContent";
import GalleryPart from "./GalleryPart";

const NewsDetails = ({ news }) => {
  return (
    <>
      <NewsContent news={news} />
      <div className="col-lg-12">
        {news && news.imagegalary.length > 0 && (
          <div className="content-part ">
            <GalleryPart images={news.imagegalary} title={news.title} />
          </div>
        )}
      </div>
    </>
  );
};

export default NewsDetails;
