import React from 'react'
import { Link } from "react-router-dom";
import moment from "moment";
const SinglePostSix = (props) => {
  const {
    blogClass,
    blogImage,
    blogTitle,
    blogAuthor,
    blogPublishedDate,
    blogDesc,
    blogButtonClass,
    blogButtonText,
    newsTrackingLink,
    nameOFTracking,
    newsId,
  } = props;
  return (
    <div className={blogClass ? blogClass : "blog-item rtl"}>
      <div className="blog-img">
        <Link to={`/news/${newsId}`}>
          <img src={blogImage} alt={blogTitle} />
        </Link>
      </div>
      <div className="blog-content">
        <h3 className="blog-title">
          <Link to={`/news/${newsId}`}>
            {blogTitle
              ? blogTitle
              : "University while the lovely valley team work"}
          </Link>
        </h3>
        <div className="blog-meta">
          <ul className="btm-cate">
            <li>
              <div className="blog-date">
                <i className="fa fa-calendar-check-o"></i>{" "}
                {blogPublishedDate
                  ? moment(blogPublishedDate).format("ll")
                  : "September 14, 2020"}
              </div>
            </li>
            <li>
              <div className="author">
                <i className="fa fa-user-o"></i>{" "}
                {blogAuthor ? blogAuthor : "Admin"}
              </div>
            </li>

            {nameOFTracking && (
              <li>
                <div className="tag-line">
                  <i className="fa fa-book"></i>
                  <Link to={newsTrackingLink}>{nameOFTracking}</Link>
                </div>
              </li>
            )}
          </ul>
        </div>
        <div
          className="blog-desc"
          dangerouslySetInnerHTML={{ __html: blogDesc.substr(0, 320) }}
        ></div>
        <div className={blogButtonClass ? blogButtonClass : "blog-button"}>
          <Link to={`/news/${newsId}`} className="blog-btn">
            {blogButtonText ? blogButtonText : "Continue Reading"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SinglePostSix;
