
// Image
import teamImg1 from "../../assets/img/team/av.png";
import teamImgf from "../../assets/img/team/avf.png";
import React from "react";

// Image

import { useSelector } from "react-redux";
import SingleTeam from "../../components/Team/SingleTeam";

const InstructorPart = () => {
  const { membersItems } = useSelector((state) => state.memberlistApp);
  console.log(membersItems);
  return (
    <div className="content pt-30 pb-30 pl-30 pr-30 white-bg">
      <h3 className="instructor-title">اعضاء هيئة التدريس بالقسم</h3>
      <div className="row rs-team  orange-color transparent-bg clearfix">
        {membersItems &&
          membersItems.map((member) => (

<div className="col-lg-4 col-md-6 mb-30">
                  <SingleTeam
                    itemClass="team-item"
                    Image={member.sex === "انثى" ? teamImgf : teamImg1}
                    Title={member.name}
                    Designation={member.degree}
                    Id={member._id}
                    department={member.department}
                  />
                </div>
          ))}
      </div>
    </div>
  );
};

export default InstructorPart;
