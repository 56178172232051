import React from "react";
import { Link } from "react-router-dom";

const FooterBottom = () => {
  return (
    <div className="footer-bottom">
      <div className="container">
        <div className="row y-middle">
          <div className="col-lg-6 md-mb-10">
            <div className="copyright md-mb-0">
              <p> © جامعة الزاوية 2022</p>
            </div>
          </div>
          <div className="col-lg-6 text-end md-text-left">
            <ul className="copy-right-menu">
              <li>
                <Link to="/news" as="/news">
                  الاخبار
                </Link>
              </li>
              <li>
                <Link to="/advs" as="/advs">
                  الاعلانات
                </Link>
              </li>
              <li>
                <Link to="/" as="/">
                  النشاطات
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
