import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { Url } from "../../constants/defaultValues";

import {
  NEWS_LIST_GET_LIST,
  NEWS_LIST_GET_ONE,
  NEWS_LIST_GET_LIST_BY_TRACKING,
  NEWS_LIST_GET_TOTALS,
} from "../contants";

import {
  getTotalsSuccess,
  getTotalsError,
  getNewsListSuccess,
  getNewsListError,
  getOneNewsSuccess,
  getOneNewsError,
  getNewsListByTrackingError,
  getNewsListByTrackingSuccess,
} from "./actions";

const getNewsTotalItemsRequest = async () =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/news/totals`);

function* getNewsTotalItems() {
  try {
    const response = yield call(getNewsTotalItemsRequest);
    yield put(getTotalsSuccess(response.data));
  } catch (error) {
    yield put(getTotalsError(error));
  }
}

const getNewsListRequest = async ({ selectedPageSize, currentPage }) =>
  // eslint-disable-next-line no-return-await
  await axios.get(
    `${Url}api/news/list?pageSize=${selectedPageSize}&currentPage=${currentPage}`
  );

function* getNewsListItems({ payload }) {
  try {
    const response = yield call(getNewsListRequest, payload);
    yield put(getNewsListSuccess(response.data));
  } catch (error) {
    yield put(getNewsListError(error));
  }
}

const getOneNewsRequest = async (id) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/news/${id}`);

function* getOneNewsItems({ payload }) {
  try {
    const response = yield call(getOneNewsRequest, payload);
    yield put(getOneNewsSuccess(response.data));
  } catch (error) {
    yield put(getOneNewsError(error));
  }
}

const getNewsListItemsByTrackingRequest = async ({
  tracking,
  selectedPageSize,
  currentPage,
}) =>
  // eslint-disable-next-line no-return-await
  await axios.get(
    `${Url}api/news/tracking/${tracking}?pageSize=${selectedPageSize}&currentPage=${currentPage}`
  );

function* getNewsListItemsByTracking({ payload }) {
  try {
    const response = yield call(getNewsListItemsByTrackingRequest, payload);
    yield put(getNewsListByTrackingSuccess(response.data));
    console.log(response.data);
  } catch (error) {
    yield put(getNewsListByTrackingError(error));
  }
}
export function* watchGetList() {
  yield takeEvery(NEWS_LIST_GET_LIST, getNewsListItems);
}

export function* watchGetTotalItems() {
  yield takeEvery(NEWS_LIST_GET_TOTALS, getNewsTotalItems);
}
export function* watchGetOne() {
  yield takeEvery(NEWS_LIST_GET_ONE, getOneNewsItems);
}
export function* wathcGetListByTracking() {
  yield takeEvery(NEWS_LIST_GET_LIST_BY_TRACKING, getNewsListItemsByTracking);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetTotalItems),
    fork(wathcGetListByTracking),
  ]);
}
