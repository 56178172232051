import React from "react";
import About from "./AboutSection";
import ScrollToTop from "../../components/Common/ScrollTop";
import BannerDefault from "../../components/Banner/AsisBannerDefault";

const HomeTwoMain = () => {
  return (
    <React.Fragment>
      {/* Banner-area-start */}
      <BannerDefault />
      {/* Banner-area-end */}

      {/* about-area-start */}
      <About />
      {/* about-area-end */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default HomeTwoMain;
