// eslint-disable-next-line import/no-cycle
import {
  ACADPROGRAM_GET_LIST,
  ACADPROGRAM_GET_LIST_SUCCESS,
  ACADPROGRAM_GET_LIST_ERROR,
  ACADPROGRAM_GET_LIST_BY_TRACKING,
  ACADPROGRAM_GET_LIST_BY_TRACKING_SUCCESS,
  ACADPROGRAM_GET_LIST_BY_TRACKING_ERROR,
  ACADPROGRAM_GET_ONE,
  ACADPROGRAM_GET_ONE_SUCCESS,
  ACADPROGRAM_GET_ONE_ERROR,
} from "../contants";

export const getAcademicProgramsList = () => ({
  type: ACADPROGRAM_GET_LIST,
});

export const getAcademicProgramsListSuccess = (items) => ({
  type: ACADPROGRAM_GET_LIST_SUCCESS,
  payload: items,
});

export const getAcademicProgramsListError = (error) => ({
  type: ACADPROGRAM_GET_LIST_ERROR,
  payload: error,
});
export const getAcademicProgramsListByTracking = (tracking) => ({
  type: ACADPROGRAM_GET_LIST_BY_TRACKING,
  payload: tracking,
});

export const getAcademicProgramsListByTrackingSuccess = (items) => ({
  type: ACADPROGRAM_GET_LIST_BY_TRACKING_SUCCESS,
  payload: items,
});

export const getAcademicProgramsListByTrackingError = (error) => ({
  type: ACADPROGRAM_GET_LIST_BY_TRACKING_ERROR,
  payload: error,
});

export const getAcademicProgramItem = (id) => ({
  type: ACADPROGRAM_GET_ONE,
  payload: id,
});

export const getAcademicProgramItemSuccess = (item) => ({
  type: ACADPROGRAM_GET_ONE_SUCCESS,
  payload: item,
});

export const getAcademicProgramItemError = (error) => ({
  type: ACADPROGRAM_GET_ONE_ERROR,
  payload: error,
});
