import React from "react";

import bgImg from "../../assets/img/bg/home1.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const BannerDefault = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div id="rs-banner" className="rs-banner style1" style={bgStyle}>
        <div className="container">
          <div className="banner-content text-center">
            <h1 className="banner-title capitalize">
              التسجيل للطلبة المتحصلين على الشهادة الثانوية للعام (2021-2022)
              للدراسة بكليات جامعة الزاوية
            </h1>
            {/* <div className="banner-btn">
              <a
                className="readon banner-style"
                href="http://esems.zu.edu.ly/student/auth/send-request"
                target="_blank"
              >
                للتسجيل{" "}
              </a>
            </div> */}
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}
    </React.Fragment>
  );
};

export default BannerDefault;
