import React from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";

import footerLogo1 from "../../../assets/img/logo/logo.png";
import postImg1 from "../../../assets/img/blog/post1.jpg";
import postImg2 from "../../../assets/img/blog/post2.jpg";

const Footer = (props) => {
  const {
    footerLogo,
    footerClass,
    footerTopClass,
    facebook,
    linkedin,
    twitter,
    youtube,
    instagram,
  } = props;
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className={`footer-top ${footerTopClass}`}>
        <div className="container rtl">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <div className="footer-logo mb-30">
                <Link to="/" as="/">
                  <img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" />
                </Link>
              </div>
              <div className="textwidget ">
                <p>
                  جامعة الزاوية هي إحدى الجامعات الحكومية المنتشرة في ربوع
                  ليبيا. تأسست سنة 1988 بناءً علي قرار اللجنة الشعبية العامة
                  (سابقاً) رقم (135).
                </p>
              </div>
              <ul className="footer_social">
                <li>
                  <a
                    target="_blank"
                    href={
                      facebook
                        ? facebook
                        : "https://www.facebook.com/%D8%AC%D8%A7%D9%85%D8%B9%D8%A9-%D8%A7%D9%84%D8%B2%D8%A7%D9%88%D9%8A%D8%A9-University-of-Zawia-506936242671203/"
                    }
                  >
                    <i className="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={
                      twitter ? twitter : "https://twitter.com/UZawia?lang=en"
                    }
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={
                      linkedin
                        ? linkedin
                        : "https://www.linkedin.com/in/university-of-zawia-437b63183/"
                    }
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={
                      instagram
                        ? instagram
                        : "https://www.instagram.com/uzawia/"
                    }
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={
                      youtube
                        ? youtube
                        : "https://www.youtube.com/channel/UCDYOZzNmGiaihNFxzzdNwkw?view_as=subscriber"
                    }
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title">اتصل بنا</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-location"></i>
                  <div className="desc m-4">الزاوية</div>
                </li>
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc m-4">
                    <a href="tel:0021823762659"> 0021823762659</a>
                    <a href="tel:0021823762682">0021823762682</a>
                    <a href="tel:0021823762382">0021823762382</a>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email "></i>
                  <div className="desc m-4">
                    <a href="mailto:info@zu.edu.ly">info@zu.edu.ly</a>
                    <a href="www.zu.edu.ly">www.zu.edu.ly</a>
                  </div>
                </li>
              </ul>
            </div>
            {/* <div className="col-lg-3 col-md-12 col-sm-12 pl-50 md-pl-14 footer-widget md-mb-50">
              <h3 className="widget-title">Courses</h3>
              <ul className="site-map">
                <li>
                  <Link to="/course">Courses</Link>
                </li>
                <li>
                  <Link to="/course-2">Course Two</Link>
                </li>
                <li>
                  <Link to="/course/course-single">Single Course</Link>
                </li>
                <li>
                  <Link to="/shop/my-account">Profile</Link>
                </li>
                <li>
                  <Link to="/login">Login</Link>/
                  <Link to="/register">Register</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
              <h3 className="widget-title">Recent Posts</h3>
              <div className="recent-post mb-20">
                <div className="post-img">
                  <img src={postImg1} alt="blog image" />
                </div>
                <div className="post-item">
                  <div className="post-desc">
                    <Link to="/blog/single-post-right-sidebar">
                      High school program starting soon 2021
                    </Link>
                  </div>
                  <span className="post-date">
                    <i className="fa fa-calendar-check-o"></i>
                    October 15, 2020
                  </span>
                </div>
              </div>
              <div className="recent-post mb-20">
                <div className="post-img">
                  <img src={postImg2} alt="blog image" />
                </div>
                <div className="post-item">
                  <div className="post-desc">
                    <Link to="/blog/single-post-right-sidebar">
                      Shutdown of schools extended to Aug 31{" "}
                    </Link>
                  </div>
                  <span className="post-date">
                    <i className="fa fa-calendar-check-o"></i>
                    April 25, 2020
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
