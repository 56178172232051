import React from "react";

import SingleFeature from "../../components/Service/SingleFeature";

import featureIcon1 from "../../assets/img/features/style2/1.png";
import featureIcon2 from "../../assets/img/features/style2/2.png";
import featureIcon3 from "../../assets/img/features/style2/3.png";
import featureIcon4 from "../../assets/img/features/style2/4.png";

const Feature = () => {
  return (
    <div className="rs-features style2">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 md-mb-30">
            <SingleFeature
              featureClass="rs-iconbox-area"
              featureIcon={featureIcon1}
              featureTitle="تطوير إختصاصات المركز من خلال المشاركة فى 
              المشاركة فى المؤتمرات والندوات والمعارض"
            />
          </div>
          <div className="col-lg-3 col-md-6 md-mb-30">
            <SingleFeature
              featureClass="rs-iconbox-area"
              featureIcon={featureIcon2}
              featureTitle="لإشراف على وضع برامج تدريبية للعاملين فى الجامعة ذات العــلاقة بتكنولوجيا المعلومات و الإتصالات"
            />
          </div>
          <div className="col-lg-3 col-md-6 sm-mb-30">
            <SingleFeature
              featureClass="rs-iconbox-area"
              featureIcon={featureIcon3}
              featureTitle="المساهمة فى بناء وتطوير البنية التحتية لتقنية المعلومات 
              والإتصالات فى الجامع"
            />
          </div>
          <div className="col-lg-3 col-md-6">
            <SingleFeature
              featureClass="rs-iconbox-area"
              featureIcon={featureIcon4}
              featureTitle="جمع وتصنيف وتبويب وحفظ البيانات والمعلومات وتوثيقها"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
