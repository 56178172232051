import moment from "moment";
import React from "react";

// Breadcrumbs Bg
import { shareUrl, Url1 } from "../../constants/defaultValues";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberIcon,
  ViberShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import GalleryPart from "./GalleryPart";

const TeamSingleMain = ({ adv, files }) => {
  return (
    <div className="profile-section pt-100 pb-90 md-pt-80 md-pb-70 rtl">
      <div className="container">
        <div className="row clearfix">
          <div className="image-column col-lg-5 md-mb-50">
            <div className="inner-column mb-50 md-mb-0">
              <div className="image">
                <img src={`${Url1}${adv && adv.image}`} alt="images" />
              </div>
              <div className="team-content text-center m-4">
                <div className="text">
                  <i className="fa fa-user" /> {adv && adv.author.name}{" "}
                  <i className="fa fa-calendar-check-o"></i>{" "}
                  {moment(adv && adv.updatedAt)
                    .startOf("day")
                    .fromNow()}
                </div>
              </div>
              <h5 className="text-center"> لمشاركة الاعلان</h5>
              <div className="social-box">
                <FacebookShareButton
                  url={`${shareUrl}advs/${adv._id}`}
                  quote={adv && adv.title}
                  hashtag="#اعلان بجامعة الزاوية"
                >
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
                <EmailShareButton
                  url={`${shareUrl}advs/${adv._id}`}
                  subject={adv && adv.title}
                  body={adv && adv.description}
                >
                  <EmailIcon size={40} round={true} />
                </EmailShareButton>
                <LinkedinShareButton url={`${shareUrl}advs/${adv._id}`}>
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
                <TelegramShareButton url={`${shareUrl}advs/${adv._id}`}>
                  <TelegramIcon size={40} round={true} />
                </TelegramShareButton>
                <TwitterShareButton
                  url={`${shareUrl}advs/${adv._id}`}
                  title={adv && adv.title}
                >
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
                <ViberShareButton
                  url={`${shareUrl}advs/${adv._id}`}
                  title={adv && adv.title}
                >
                  <ViberIcon size={40} round={true} />
                </ViberShareButton>
                <WhatsappShareButton
                  url={`${shareUrl}advs/${adv._id}`}
                  title={adv && adv.title}
                >
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
          <div className="content-column col-lg-7 pl-60 pt-50 md-pl-14 md-pt-0">
            <div className="inner-column">
              <h5 className="text-justify">{adv && adv.title}</h5>
              <br />
              <h5>تفاصيل الاعلان</h5>
              <p
                className="text-justify"
                dangerouslySetInnerHTML={{ __html: adv && adv.description }}
              ></p>
            </div>
            <br />
            {files && files.length > 0 && (
              <div>
                <h5 className="text-right"> المرفقات</h5>
                <ul>
                  {files.map((file) => (
                    <li>
                      <a href={Url1 + file.file} target="_blank">
                        <i
                          className="fa fa-download"
                          style={{ fontSize: "2rem" }}
                        />{" "}
                      </a>
                      {file.title}{" "}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="col-lg-12">
            {adv && adv.imagegalary.length > 0 && (
              <div className="content-part">
                <GalleryPart images={adv.imagegalary} title={adv.title} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSingleMain;
