import React from "react";

import bgImg from "../../assets/img/breadcrumbs/2.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const BannerDefault = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div id="rs-banner" className="rs-banner style1" style={bgStyle}>
        <div className="container">
          <div className="banner-content text-center">
            <h1 className="banner-title capitalize">اقلام الجامعة</h1>
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}
    </React.Fragment>
  );
};

export default BannerDefault;
