import React from "react";
import { getNewsListSearch } from "../../redux/newsList/actions";
import { useDispatch } from "react-redux";

const SearchWidget = () => {
  const dispatch = useDispatch();
  return (
    <div className="search-widget mb-50">
      <div className="search-wrap rtl">
        <input
          className="search-input"
          type="search"
          placeholder="للبحث..."
          name="keyword"
          id="search"
          onChange={(e) => {
            dispatch(getNewsListSearch(e.target.value));
          }}
        />
      </div>
    </div>
  );
};

export default SearchWidget;
