import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
const CourseSingleTwo = (props) => {
  const {
    advClass,
    advImg,
    advTitle,
    advDate,
    advLink,
    advPrice,
    advCategory,
    userCount,
    userRating,
  } = props;
  return (
    <div className={advClass ? advClass : "courses-item"}>
      <div className="img-part">
        <img src={advImg} alt={advTitle} />
      </div>
      <div className="content-part mr-5">
        <ul className="meta-part">
          <li>
            <small>
              <i className="fa fa-user"></i>{" "}
              {advCategory ? advCategory : "Web Development"}{" "}
            </small>
          </li>
        </ul>
        <h5 className="title mr-4">
          <Link to={`/advs/${advLink}`}>{advTitle ? advTitle : "اعلان"}</Link>
        </h5>

        <div className="bottom-part text-left">
          <div className="info-meta">
            <ul>
              <li className="date">
                <i className="fa fa-calendar-check-o"></i>{" "}
                {advDate && moment(advDate).startOf("day").fromNow()}{" "}
              </li>
            </ul>
          </div>
          <div className="btn-part text-left">
            <Link to={`/advs/${advLink}`}>
              {props.btnText}
              <i className="flaticon-left-arrow"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSingleTwo;
