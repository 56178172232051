// eslint-disable-next-line import/no-cycle
import {
  ARTICLES_GET_LIST,
  ARTICLES_GET_LIST_SUCCESS,
  ARTICLES_GET_LIST_ERROR,
  ARTICLES_GET_LIST_BY_TRACKING,
  ARTICLES_GET_LIST_BY_TRACKING_SUCCESS,
  ARTICLES_GET_LIST_BY_TRACKING_ERROR,
  ARTICLES_GET_ONE,
  ARTICLES_GET_ONE_SUCCESS,
  ARTICLES_GET_ONE_ERROR,
} from "../contants";

export const getArticlesList = () => ({
  type: ARTICLES_GET_LIST,
});

export const getArticlesListSuccess = (items) => ({
  type: ARTICLES_GET_LIST_SUCCESS,
  payload: items,
});

export const getArticlesListError = (error) => ({
  type: ARTICLES_GET_LIST_ERROR,
  payload: error,
});
export const getArticlesListByTracking = (tracking) => ({
  type: ARTICLES_GET_LIST_BY_TRACKING,
  payload: tracking,
});

export const getArticlesListByTrackingSuccess = (items) => ({
  type: ARTICLES_GET_LIST_BY_TRACKING_SUCCESS,
  payload: items,
});

export const getArticlesListByTrackingError = (error) => ({
  type: ARTICLES_GET_LIST_BY_TRACKING_ERROR,
  payload: error,
});

export const getArticleItem = (id) => ({
  type: ARTICLES_GET_ONE,
  payload: id,
});

export const getArticleItemSuccess = (item) => ({
  type: ARTICLES_GET_ONE_SUCCESS,
  payload: item,
});

export const getArticleItemError = (error) => ({
  type: ARTICLES_GET_ONE_ERROR,
  payload: error,
});
