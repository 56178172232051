/* eslint-disable import/no-unresolved */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { Url } from "../../constants/defaultValues";

import {
  ACADPROGRAM_GET_LIST,
  ACADPROGRAM_GET_ONE,
  ACADPROGRAM_GET_LIST_BY_TRACKING,
} from "../contants";

import {
  getAcademicProgramsListByTrackingSuccess,
  getAcademicProgramsListByTrackingError,
  getAcademicProgramsListSuccess,
  getAcademicProgramsListError,
  getAcademicProgramItemSuccess,
  getAcademicProgramItemError,
} from "./actions";

const getAcademicProgramsByTrackingListRequest = async (tracking) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/academicprograms/tracking/${tracking}`);

function* getAcademicProgramsListByTrackingItems({ payload }) {
  try {
    const response = yield call(
      getAcademicProgramsByTrackingListRequest,
      payload
    );
    yield put(getAcademicProgramsListByTrackingSuccess(response.data));
  } catch (error) {
    yield put(getAcademicProgramsListByTrackingError(error));
  }
}
const getAcademicProgramsListRequest = async () =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/academicprograms`);

function* getAcademicProgramsListItems() {
  try {
    const response = yield call(getAcademicProgramsListRequest);
    yield put(getAcademicProgramsListSuccess(response.data));
  } catch (error) {
    yield put(getAcademicProgramsListError(error));
  }
}

const getAcademicProgramItemRequest = async (id) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/academicprograms/${id}`);

function* getAcademicProgramsItem({ payload }) {
  try {
    const response = yield call(getAcademicProgramItemRequest, payload);
    yield put(getAcademicProgramItemSuccess(response.data));
  } catch (error) {
    yield put(getAcademicProgramItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(ACADPROGRAM_GET_LIST, getAcademicProgramsListItems);
}
export function* watchGetListByTracking() {
  yield takeEvery(
    ACADPROGRAM_GET_LIST_BY_TRACKING,
    getAcademicProgramsListByTrackingItems
  );
}

export function* wathcGetItem() {
  yield takeEvery(ACADPROGRAM_GET_ONE, getAcademicProgramsItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcGetItem),
    fork(watchGetListByTracking),
  ]);
}
