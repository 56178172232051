import React from "react";
import News from "./NewsSection";
import AboutVideo from "./VideoSection";
import AboutCounter from "./CounterSection";
import Testimonial from "./TestimonialSection";
import FaqSection from "./FaqSection";
import ScrollToTop from "../../components/Common/ScrollTop";

const AboutMain = ({ faculty }) => {
  return (
    <React.Fragment>
      {/* AboutCounter Start */}
      <AboutCounter faculty={faculty} />
      {/* AboutCounter End */}

      {/* FaqSection-area-start */}
      <FaqSection faculty={faculty} />
      {/* FaqSection-area-end */}

      {/* AboutVideo Start */}
      <AboutVideo faculty={faculty} />
      {/* AboutVideo End */}

      {/* Team Section Start */}
      {/* <Team /> */}
      {/* Team Section End */}

      {/* Testimonial Section Start */}
      <Testimonial faculty={faculty} />
      {/* Testimonial Section End */}

      {/* News Section Start */}
      <News faculty={faculty} />
      {/* News Section End */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default AboutMain;
