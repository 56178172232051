import React from "react";
import { Url } from "../../constants/defaultValues";

const AboutVideo = ({ faculty }) => {
  return (
    <div className="rs-about-video pt-110 pb-110 md-pt-70 md-pb-70 rtl">
      <div className="container">
        <div className="video-img-part media-icon orange-color2 ">
          <img src={faculty && Url + faculty.structureImage} alt="images" />
        </div>
      </div>
    </div>
  );
};

export default AboutVideo;
