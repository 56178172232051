import React from "react";

const OverviewPart = ({ vision, message, objectives, description }) => {
  return (
    <div className="content white-bg pt-30">
      <div className="course-overview">
        <div className="inner-box">
          <h4>حول القسم</h4>
          <p className="text-justify">{description}</p>
          <h4>رؤية القسم</h4>
          <p>{vision}</p>
          <h4>رسالة القسم</h4>
          <p>{message}</p>

          <h3>أهداف القسم:</h3>
          <ul className="review-list rtl">
            <p dangerouslySetInnerHTML={{ __html: objectives }} />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OverviewPart;
