import React from 'react'
import { Link } from 'react-router-dom';
import teamImg1 from "../../assets/img/team/av.png";
import teamImgf from "../../assets/img/team/avf.png";


const SingleTeamThree = (props) => {
    const { teamClass, Image, Title, Designation ,sex , Id} = props;
  
	return(
        <div className={teamClass ? teamClass : 'team-item'}>
            <div className="team-item">
                <img 
                    src={sex === "انثى" ? teamImgf : teamImg1} 
                    alt={Title}
                />
                <div className="content-part">
                    <h4 className="name">
                    <Link to={`/members/${Id}`}>{Title}</Link>

                    </h4>
                    <span className="designation">{Designation ? Designation : ''}</span>
                    <ul className="social-links">
                        <li>
                            <Link to="#">
                                <i className="fa fa-facebook-f"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                <i className="fa fa-twitter"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                <i className="fa fa-pinterest"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                <i className="fa fa-instagram"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	)
}

export default SingleTeamThree