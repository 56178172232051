import React, { useEffect, useState } from "react";

import AdvsSidebar from "./AdvsSidebarSection";
import AdvsSingle from "../../components/Courses/AdvsSingle";
import load from "../../assets/img/loading1.gif";

import { useDispatch, useSelector } from "react-redux";
import {
  getAdvsList,
  getAdvsListByTracking,
  getAdvsListWithOrder,
  getTotals,
} from "../../redux/advsList/actions";
import { Url1 } from "../../constants/defaultValues";
import Pagination from "./Pagination";

const CoursePart = ({ faculty }) => {
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const listClassAdd = () => {
    document.getElementById("rs-popular-course").classList.add("list-view");
  };

  const listClassRemove = () => {
    document.getElementById("rs-popular-course").classList.remove("list-view");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTotals());
  }, []);
  useEffect(() => {
    faculty
      ? dispatch(getAdvsListByTracking(faculty))
      : dispatch(getAdvsList(selectedPageSize, currentPage));
  }, [selectedPageSize, currentPage]);

  const { advsItems, totalPage } = useSelector((state) => state.advsListApp);

  return (
    <div
      id="rs-popular-course"
      className="rs-popular-courses style1 course-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-80 rtl"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 order-last">
            <AdvsSidebar />
          </div>
          <div className="col-lg-8 pr-50 md-pr-14 order-1">
            <div className="course-search-part">
              <div className="course-view-part">
                <div className="view-icons">
                  <button onClick={listClassRemove} className="view-grid mr-10">
                    <i className="fa fa-th-large"></i>
                  </button>
                  <button onClick={listClassAdd} className="view-list">
                    <i className="fa fa-list-ul"></i>
                  </button>
                </div>
                <div className="view-text">عرض من 1 الي 30</div>
              </div>
              <div className="type-form">
                <form method="post" action="#">
                  <div className="form-group mb-0">
                    <div className="custom-select-box">
                      <select
                        id="timing"
                        onChange={(e) =>
                          dispatch(getAdvsListWithOrder(e.target.value))
                        }
                      >
                        <option>ترتيب حسب</option>
                        <option value="updatedAt">التاريخ</option>
                        <option value="title">العنوان</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="course-part clearfix ">
              {advsItems ? (
                advsItems.map((adv) => (
                  <AdvsSingle
                    key={adv._id}
                    advClass="courses-item"
                    advImg={`${Url1}${adv.image}`}
                    advTitle={adv.title}
                    advCategory={adv.author.name}
                    advDate={adv.updatedAt}
                    advLink={adv._id}
                  />
                ))
              ) : (
                <div className="text-center ">
                  <img src={load} alt="grid img" width="300" />
                </div>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              onChangePage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePart;
