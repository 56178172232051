import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
import SinglePostTwo from "../../components/Blog/SinglePostTwo";

import { useDispatch, useSelector } from "react-redux";
import { getAdvsListByTracking } from "../../redux/advsList/actions";
import { getNewsListByTracking } from "../../redux/newsList/actions";
import { Url } from "../../constants/defaultValues";
import moment from "moment";

const Blog = ({ faculty }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    faculty && dispatch(getAdvsListByTracking(faculty.Url));
    faculty && dispatch(getNewsListByTracking(faculty.Url));
  }, [faculty]);
  const { newsItems } = useSelector((state) => state.newsListApp);
  const { advsItems } = useSelector((state) => state.advsListApp);

  return (
    <React.Fragment>
      <div
        id="rs-blog"
        className="rs-blog style1 modify1 pt-100 pb-106 md-pt-70 md-pb-70"
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title mb-50 md-mb-30 text-center"
            subtitleClass="sub-title orange"
            subtitle="اخر التحديتات"
            titleClass="title mb-0"
            title="اخر الاخبار و الاعلانات"
          />
          <div className="row rtl">
            <div className="col-lg-7 pr-60 md-pr-14 md-mb-30">
              {newsItems.length > 2 && (
                <>
                  <SinglePostTwo
                    blogClass="row no-gutter white-bg blog-item mb-34"
                    blogImage={newsItems[0] && Url + newsItems[0].image}
                    blogTitle={newsItems[0] && newsItems[0].title}
                    blogAuthor={newsItems[0] && newsItems[0].author.name}
                    id={newsItems[0] && newsItems[0].author._id}
                  />
                  <SinglePostTwo
                    blogClass="row no-gutter white-bg blog-item"
                    blogImage={newsItems[1] && Url + newsItems[1].image}
                    blogTitle={newsItems[1] && newsItems[1].title}
                    ImgOrder="last"
                    blogAuthor={newsItems[1] && newsItems[1].author.name}
                    id={newsItems[1] && newsItems[1].author._id}
                  />
                </>
              )}
            </div>
            <div className="col-lg-5 lg-pl-0 md-ml-14">
              {advsItems &&
                advsItems.slice(0, 10).map((adv) => (
                  <div className="events-short mb-30">
                    <div className="date-part bgc1">
                      <span className="month">
                        {moment(adv.updatedAt).format("dddd")}
                      </span>
                      <div className="date">
                        {new Date(adv.updatedAt).getMonth()}/
                        {new Date(adv.updatedAt).getDay()}
                      </div>
                    </div>
                    <div className="content-part">
                      <h4 className="title mb-0">
                        <Link to={`/advs/${adv._id}`}>{adv.title}</Link>
                      </h4>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Blog;
