import React from "react";
import CoursePart from "./AdvsSection";
import ScrollToTop from "../../components/Common/ScrollTop";

const CourseMain = ({ faculty }) => {
  return (
    <React.Fragment>
      {/* CoursePart Start */}
      <CoursePart faculty={faculty} />
      {/* CoursePart End */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default CourseMain;
