import React, { useEffect } from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import TeamSingleMain from "./TeamSingleMain";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";
import { Helmet } from "react-helmet-async";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getOneAdv } from "../../redux/advsList/actions";
import { getFilesList } from "../../redux/filesList/actions";
import { shareUrl, Url1 } from "../../constants/defaultValues";

const TeamSingle = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOneAdv(match.params.id));
    dispatch(getFilesList(match.params.id));
  }, []);
  const { advDetails } = useSelector((state) => state.advsListApp);
  const { filesItems } = useSelector((state) => state.filesListApp);
  return (
    advDetails && (
      <React.Fragment>
        <OffWrap />
        <Helmet>
          {/* <!-- Google+ Meta Info --> */}
          {/* <!-- Twitter Meta Info --> */}
          {/* Title */}
          <title>{advDetails.title}</title>
          {/* <meta property="og:site_name" content={advDetails.title} />
          <meta itemprop="name" content={advDetails.title} />
          <meta name="twitter:title" content={advDetails.title} /> */}

          {/* Image */}
          {/* <meta property="og:image" content={Url1 + advDetails.image} />
          <meta itemprop="image" content={Url1 + advDetails.image} /> */}

          {/* Description */}
          {/* <meta
            name="description"
            content={advDetails.description.substr(3, 30)}
          />
          <meta
            name="twitter:description"
            content={advDetails.description.substr(3, 30)}
          /> */}

          {/* keyWords */}
          {/* <meta
          //   name="keywords"
          //   content={`${advDetails.title} , ${
          //     advDetails.nameOFTracking ? advDetails.nameOFTracking : ""
          //   }   
          //   ,
          //   جامعة الزاوية
          //   ,
          //   اعلانات الجامعة
          //   ,
          //   اهم نشاطات جامعة الزاوية
          //   `}
          // />

          {/* Url */}

          {/* <link rel="canonical" href={`${shareUrl}advs/${advDetails._id}`} />
          <meta
            property="og:url"
            content={`${shareUrl}advs/${advDetails._id}`}
          />
          <meta
            name="twitter:url"
            content={`${shareUrl}advs/${advDetails._id}`}
          /> */}

          <meta
            property="og:url"
            content={`${shareUrl}advs/${advDetails._id}`}
          />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={advDetails.title} />
          <meta
            property="og:description"
            content={advDetails.description.substr(3, 80)}
          />
          <meta
            property="og:image:secure_url"
            content={Url1 + advDetails.image}
          />
        </Helmet>
        <Header
          parentMenu="pages"
          secondParentMenu="team"
          headerNormalLogo={Logo}
          headerStickyLogo={Logo}
          CanvasLogo={Logo}
          mobileNormalLogo={Logo}
          CanvasClass="right_menu_togle hidden-md"
          headerClass="full-width-header header-style1 home8-style4 rtl"
          TopBar="enable"
          TopBarClass="topbar-area home8-topbar"
          emailAddress="info@zu.edu.ly"
          phoneNumber="(+218) 23-762682"
        />

        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle={advDetails && advDetails.title}
          pageName="الاعلانات"
          breadcrumbsImg={bannerbg}
        />
        {/* breadcrumb-area-End */}

        {/* TeamDetails Start */}
        <TeamSingleMain adv={advDetails} files={filesItems} />
        {/* TeamDetails End */}

        <Newsletter
          sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
          titleClass="title mb-0 white-color"
        />

        <Footer
          footerClass="rs-footer home9-style main-home"
          footerLogo={footerLogo}
        />

        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}

        <SearchModal />
      </React.Fragment>
    )
  );
};

export default TeamSingle;
