// eslint-disable-next-line import/no-cycle
import {
  FACULTIES_LIST_GET_LIST,
  FACULTIES_LIST_GET_LIST_SUCCESS,
  FACULTIES_LIST_GET_LIST_ERROR,
  FACULTIES_LIST_GET_ONE,
  FACULTIES_LIST_GET_ONE_SUCCESS,
  FACULTIES_LIST_GET_ONE_ERROR,
  FACULTIES_LIST_GET_LIST_SEARCH,
} from "../contants";

export const getFacultiesDetails = (url) => ({
  type: FACULTIES_LIST_GET_ONE,
  payload: url,
});

export const getFacultiesDetailsSuccess = (item) => ({
  type: FACULTIES_LIST_GET_ONE_SUCCESS,
  payload: item,
});

export const getFacultiesDetailsError = (error) => ({
  type: FACULTIES_LIST_GET_ONE_ERROR,
  payload: error,
});

export const getFacultiesList = () => ({
  type: FACULTIES_LIST_GET_LIST,
});

export const getFacultiesListSuccess = (items) => ({
  type: FACULTIES_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getFacultiesListError = (error) => ({
  type: FACULTIES_LIST_GET_LIST_ERROR,
  payload: error,
});

export const getFacultiesListSearch = (keyword) => ({
  type: FACULTIES_LIST_GET_LIST_SEARCH,
  payload: keyword,
});
