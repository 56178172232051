import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import DegreeSingle from "../../components/Degree/DegreeSingle";
import { Url1 } from "../../constants/defaultValues";
import { Link } from "react-router-dom";
import loading from "../../assets/img/loading.gif";
import { useSelector } from "react-redux";

const Degree = ({ faculty }) => {
  const { advsItems } = useSelector((state) => state.advsListApp);
  console.log(advsItems)
  return (
    <div
      className="rs-degree style1 modify gray-bg pt-100 pb-70 md-pt-70 md-pb-40"
      style={{ direction: "rtl" }}
    >
      <div className="container">
        <div className="row y-middle">
          <div className="col-lg-4 col-md-6 mb-30">
            {/* Section Title Start */}
            <SectionTitle
              sectionClass="sec-title"
              subtitleClass="sub-title primary"
              subtitle="الاعلانات "
              titleClass="title mb-0"
              title="اخر اعلانات   الكلية"
            />
            {/* Section Title End */}
          </div>
          {advsItems ? (
            advsItems.slice(0, 5).map((adv) => (
              <div className="col-lg-4 col-md-6 mb-30">
                <DegreeSingle
                  itemImage={`${Url1}${adv.image}`}
                  title={adv.title}
                  desc={adv.description.substr(3, 90)}
                  itemLink={`/advs/${adv._id}`}
                  itemDate ={adv.createdAt}
                  author = {adv.author && adv.author.name}
                />
              </div>
            ))
          ) : (
            <div className="col-lg-4 col-md-6 mb-30">
              <img src={loading} alt="grid img" width="250" />
            </div>
          )}
          <div className="rs-latest-events style1 bg-wrap  text-center">
            <div className="event-wrap">
              <div className="btn-part mt-54 md-mt-24">
                <Link to={`/faculties/${faculty && faculty.Url}/advs`}>
                  المزيد من الاعلانات{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Degree;
