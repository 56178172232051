import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

//Custom Components
import TeamSingle from "../pages/advDetails/team-single";
import MemberDetails from "../pages/aMember/team-single";
import SingleArticle from "../pages/articlesDetails/singleArticle";
import Articles from "../pages/articles";
import Forms from "../pages/aforms";
import Asis from "../pages/asis";
import Astudents from "../pages/astudents";
import HomeTwo from "../pages/home-2";
import MainPage from "../pages/amainPage";
import MainPageEn from "../pages/amainPageEn";
import EnglishMainPage from "../pages/aengliashPage";
import HomeFour from "../pages/home-4";
import Faculty from "../pages/afaculty";
import HomeSix from "../pages/home-6";
import HomeSeven from "../pages/home-7";
import HomeEight from "../pages/home-8";
import HomeNine from "../pages/home-9";
import HomeTen from "../pages/aoffice";
import HomeEleven from "../pages/home-11";
import HomeTwelve from "../pages/home-12";
import HomeThirteen from "../pages/home-13";
import HomeFourteen from "../pages/home-14";
import HomeFifteen from "../pages/home-15";
import aboutUniversity from "../pages/aboutUniversity";
import AboutTwo from "../pages/about";
import Advs from "../pages/advs";
import CourseTwo from "../pages/course-2";
import CourseThree from "../pages/course-3";
import CourseFour from "../pages/course-4";
import CourseFive from "../pages/aprogram";
import CourseSix from "../pages/course-6";
import Department from "../pages/aDepartment";
import AcademicProgram from "../pages/academicProgram";
import CourseCategoryPage from "../pages/course-categories";

// import Team from "../p ages/advDetails";
import TeamTwo from "../pages/team-2";

import Event from "../pages/event";
import EventTwo from "../pages/event-2";
import EventThree from "../pages/event-3";
import Gallery from "../pages/gallery";
import GalleryTwo from "../pages/gallery-2";
import GalleryThree from "../pages/gallery-3";
import Shop from "../pages/shop";
import ShopSingle from "../pages/shop/shop-single";
import Cart from "../pages/shop/cart";
import Checkout from "../pages/shop/checkout";
import MyAccount from "../pages/shop/my-account";
import Faq from "../pages/faq";
import Login from "../pages/login";
import Register from "../pages/register";
import Blog from "../pages/blog";
import NewsLeft from "../pages/aNews/NewsLeft";
import SingleNews from "../pages/aNews/single-news-left-sidebar";

import BlogRight from "../pages/blog/BlogRight";
import SinglePostLeftSidebar from "../pages/blog/single-post-left-sidebar";
import SinglePostRightSidebar from "../pages/blog/single-post-right-sidebar";
import SinglePostFullWidth from "../pages/blog/single-post-full-width";
import Contact from "../pages/contact";
import ContactTwo from "../pages/contact-2";
import ContactThree from "../pages/contact-3";
import ContactFour from "../pages/contact-4";
import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />
        <Switch>
        
          <Route path="/" exact component={MainPage} />
          <Route path="/en" exact component={MainPageEn} />
          <Route path="/home" component={MainPage} />
          <Route path="/en" component={EnglishMainPage} />
          <Route path="/articles" component={Articles} />

          <Route path="/about-university" component={aboutUniversity} />
          <Route path="/advs/:id" component={TeamSingle} />
          <Route path="/advs" exact component={Advs} />
          <Route path="/faculties/:url/about" component={AboutTwo} />
          <Route path="/faculties/:url/forms-evidence" component={Forms} />
          <Route path="/faculties/:url/advs" component={Advs} />
          <Route path="/faculties/:url/news" component={NewsLeft} />
          <Route path="/faculties/:url/members" component={TeamTwo} />

          <Route path="/faculties/:url" component={Faculty} />
          <Route path="/news/:id" component={SingleNews} />
          <Route path="/news" component={NewsLeft} />
          <Route path="/department/:url" component={Department} />
          <Route path="/acprograms/:id" component={AcademicProgram} />
          <Route path="/acprograms" component={CourseFive} />
          <Route path="/forms-evidence" component={Forms} />
          <Route path="/offices/:id" component={HomeTen} />
          <Route path="/articles/:id" component={SingleArticle} />
          <Route path="/sis" component={Asis} />
          <Route path="/students" component={Astudents} />
          <Route path="/members/:id" component={MemberDetails} />

          <Route path="/home-2" component={HomeTwo} />
          <Route path="/home-4" component={HomeFour} />
          <Route path="/home-6" component={HomeSix} />
          <Route path="/home-7" component={HomeSeven} />
          <Route path="/home-8" component={HomeEight} />
          <Route path="/home-9" component={HomeNine} />
          <Route path="/home-11" component={HomeEleven} />
          <Route path="/home-12" component={HomeTwelve} />
          <Route path="/home-13" component={HomeThirteen} />
          <Route path="/home-14" component={HomeFourteen} />
          <Route path="/home-15" component={HomeFifteen} />
          <Route path="/course-2" component={CourseTwo} />
          <Route path="/course-3" component={CourseThree} />
          <Route path="/course-4" component={CourseFour} />

          <Route path="/course-6" component={CourseSix} />

          <Route path="/course-categories" component={CourseCategoryPage} />
          {/* <Route path="/team" exact component={Team} /> */}
          <Route path="/event" component={Event} />
          <Route path="/event-2" component={EventTwo} />
          <Route path="/event-3" component={EventThree} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/gallery-2" component={GalleryTwo} />
          <Route path="/gallery-3" component={GalleryThree} />
          <Route path="/shop" exact component={Shop} />
          <Route path="/shop/shop-single" component={ShopSingle} />
          <Route path="/shop/cart" component={Cart} />
          <Route path="/shop/checkout" component={Checkout} />
          <Route path="/shop/my-account" component={MyAccount} />
          <Route path="/faq" component={Faq} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/blog/blog-right-sidebar" component={BlogRight} />
          <Route
            path="/blog/single-post-left-sidebar"
            component={SinglePostLeftSidebar}
          />
          <Route
            path="/blog/single-post-right-sidebar"
            component={SinglePostRightSidebar}
          />
          <Route
            path="/blog/single-post-full-width"
            component={SinglePostFullWidth}
          />
          <Route path="/contact" component={Contact} />
          <Route path="/contact-2" component={ContactTwo} />
          <Route path="/contact-3" component={ContactThree} />
          <Route path="/contact-4" component={ContactFour} />
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
