import React from 'react'
const SingleTestimonialFour = (props) => {
  const { itemClass, Title, Designation, Description } = props;
  return (
    <div className={itemClass ? itemClass : "testi-item"}>
      <div className="item-content rtl">
        <p>
          {Description
            ? Description
            : "يحتل مركز المعلومات والتوثيق مكانة عالية في ادارة الجامعة نظرا لاهمية دور المركز في الحصول المعلومات اللازمة لادارة الجامعة بل ويتعدي الدور الي اعداد الاحصائيات اللازمة وتحليلها تحليلا دقيقا."}
        </p>
        <div className="testi-information">
          <div className="name">{Title ? Title : "Mahadi Mansura"}</div>
          <span className="designation">
            {Designation ? Designation : "CSE Student"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SingleTestimonialFour;
