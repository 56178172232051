import React, { useEffect } from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import AboutMain from "./AboutMain";
import { getFacultiesDetails } from "../../redux/facultiesList/actions";
import { useDispatch, useSelector } from "react-redux";
// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import { Url } from "../../constants/defaultValues";
import { getDepartmentsList } from "../../redux/departmentList/actions";

const About = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFacultiesDetails(match.params.url));
    dispatch(getDepartmentsList(match.params.url));
  }, []);
  const { facultyDetails } = useSelector((state) => state.facultiesListApp);
  const { departmentsItems } = useSelector((state) => state.departmentListApp);
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="about"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        {...facultyDetails}
        facultyId={facultyDetails && facultyDetails.Url}
        faculty={true}
        departmentsItems={departmentsItems}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4 rtl"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle={facultyDetails && facultyDetails.facultyTitle}
        pageName={`عن ${facultyDetails && facultyDetails.facultyTitle}`}
        breadcrumbsImg={facultyDetails && Url + facultyDetails.programImage}
        mainLink={`/faculties/${facultyDetails && facultyDetails.Url}`}
      />
      {/* breadcrumb-area-start */}

      {/* About Main */}
      <AboutMain faculty={facultyDetails} />
      {/* About Main */}

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
        {...facultyDetails}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default About;
