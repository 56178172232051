import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import SingleTestimonialTwo from "../../components/Testimonial/SingleArticle";
import SectionTitle from "../../components/Common/SectionTitle";
import { useDispatch, useSelector } from "react-redux";
import { getArticlesList } from "../../redux/articlesList/actions";
import { Url } from "../../constants/defaultValues";

function Testimonial() {
  const dispatch = useDispatch();
  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    dispatch(getArticlesList());
  }, []);

  const { ArticlesItems } = useSelector((state) => state.articlesListApp);
  return (
    ArticlesItems &&
    ArticlesItems.length > 0 && (
      <React.Fragment>
        <div className="rs-testimonial style2 pt-100 pb-100 md-pt-70 md-pb-70 rtl">
          <div className="container">
            <SectionTitle
              sectionClass="sec-title mb-60 md-mb-30 text-center"
              subtitleClass="sub-title primary"
              subtitle="اقلام الجامعة"
              titleClass="title mb-0"
              title="مقالات مختارة"
            />
            <div className="row">
              {ArticlesItems.slice(0, 1).map((article) => (
                <div className="col-lg-5 pr-90 md-pr-14 md-mb-30">
                  <div className="donation-part" data-aos="fade-up">
                    <img src={Url + article.image} alt="Donor" />
                    <h3 className="title mb-10">
                      <a href={`https://events.zu.edu.ly/articles/${article._id}`} target="_blank">
                        {article.title}
                      </a>{" "}
                    </h3>
                    <div
                      className="desc mb-38"
                      dangerouslySetInnerHTML={{
                        __html: article.details.substr(0, 300),
                      }}
                    ></div>
                  </div>
                </div>
              ))}
              <div className="col-lg-7 lg-pl-0 ml--14 md-ml-0">
                {ArticlesItems &&
                  ArticlesItems.slice(1, 3).map((article) => (
                    <SingleTestimonialTwo
                      testiImage={Url + article.image}
                      name={article.name}
                      designation={article.adjective}
                      description={article.title}
                      id={article._id}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="rs-latest-events style1 bg-wrap  text-center">
            <div className="event-wrap">
              <div className="btn-part mt-54 md-mt-24">
                <a href="https://events.zu.edu.ly/articles" target='_blank'>المزيد من المقالات </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
}

export default Testimonial;
