import React from 'react'
const SingleTestimonialThree = (props) => {
  const { itemClass, authorImage, Title, Designation, Description } = props;
  return (
    <div className={itemClass ? itemClass : "testi-item"}>
      <div className="row y-middle no-gutter">
        <div className="col-lg-5">
          <div className="user-info">
            <img src={authorImage} alt={Title} />
            <h4 className="name ">{Title ? Title : "Saiko Najran"}</h4>
            <span className="designation">
              {Designation ? Designation : "Student"}
            </span>
          </div>
        </div>
        <div className="col-lg-7">
          <div
            className="desc text-justify"
            dangerouslySetInnerHTML={{ __html: Description.substr(0, 300) }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SingleTestimonialThree;
