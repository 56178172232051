/* eslint-disable no-underscore-dangle */
import {
  ARTICLES_GET_LIST,
  ARTICLES_GET_LIST_SUCCESS,
  ARTICLES_GET_LIST_ERROR,
  ARTICLES_GET_LIST_BY_TRACKING,
  ARTICLES_GET_LIST_BY_TRACKING_SUCCESS,
  ARTICLES_GET_LIST_BY_TRACKING_ERROR,
  ARTICLES_GET_ONE,
  ARTICLES_GET_ONE_SUCCESS,
  ARTICLES_GET_ONE_ERROR,
} from "../contants";

const INIT_STATE = {
  allArticlesItems: null,
  ArticlesItems: null,
  articleDetails: null,
  error: "",
  loading: false,
  selectedItems: [],
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  orderColumns: [
    { column: "name", label: "اسم الكاتب" },
    { column: "title", label: " العنوان" },
  ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ARTICLES_GET_ONE:
    case ARTICLES_GET_LIST_BY_TRACKING:
    case ARTICLES_GET_LIST:
      return { ...state, loading: false };

    case ARTICLES_GET_LIST_BY_TRACKING_SUCCESS:
    case ARTICLES_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allArticlesItems: action.payload,
        ArticlesItems: action.payload,
      };

    case ARTICLES_GET_ONE_SUCCESS:
      return {
        ...state,
        loading: true,
        articleDetails: action.payload,
      };
    case ARTICLES_GET_ONE_ERROR:
    case ARTICLES_GET_LIST_BY_TRACKING_ERROR:
    case ARTICLES_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
