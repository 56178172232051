import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Url1 } from "../../constants/defaultValues";

const GalleryPart = ({ images, title }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  let photos = [];
  images &&
    images.map((img) =>
      photos.push({
        src: Url1 + img,
        title: title,
        width: 1,
        height: 1,
      })
    );
  return (
    <>
      <div className="rs-gallery no-gap pt-100  md-pt-80 md-pb-80 ">
        <div className="container ">
          <Gallery
            direction={"column"}
            photos={photos}
            title={title}
            onClick={openLightbox}
            className="rtl"
            // renderImage={imageRenderer}
          />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
    </>
  );
};

export default GalleryPart;
