import { all } from "redux-saga/effects";
import facultiesListSagas from "./facultiesList/saga";
import newsListSagas from "./newsList/saga";
import advsListSagas from "./advsList/saga";
import filesListSagas from "./filesList/saga";
import departmentListSagas from "./departmentList/saga";
import speechListSaga from "./speechList/saga";
import articlesListSage from "./articlesList/saga";
import programsListSage from "./programList/saga";
import membersListApp from "./membersList/saga";
import conferencesListApp from "./ceoferenceList/saga";

export default function* rootSaga() {
  yield all([
    facultiesListSagas(),
    conferencesListApp(),
    advsListSagas(),
    newsListSagas(),
    filesListSagas(),
    departmentListSagas(),
    speechListSaga(),
    articlesListSage(),
    programsListSage(),
    membersListApp(),
  ]);
}
