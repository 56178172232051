/* eslint-disable import/no-unresolved */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { Url } from "../../constants/defaultValues";

import { SPEECH_GET_LIST } from "../contants";

import { getSpeechListSuccess, getSpeechListError } from "./actions";

const getSpeechListRequest = async (tracking) =>
  // eslint-disable-next-line no-return-await
  await axios.get(`${Url}api/speech/${tracking}`);

function* getSpeechListItems({ payload }) {
  try {
    const response = yield call(getSpeechListRequest, payload);
    yield put(getSpeechListSuccess(response.data));
  } catch (error) {
    yield put(getSpeechListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(SPEECH_GET_LIST, getSpeechListItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
