import React from "react";
import { Helmet } from "react-helmet-async";

import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import AboutMain from "./AboutMain";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";

const AboutTwo = () => {
  return (
    <React.Fragment>
      <Helmet>
        {/* <!-- Google+ Meta Info --> */}
        {/* <!-- Twitter Meta Info --> */}
        {/* Image */}

        <meta
          itemprop="image"
          content="http://zu.edu.ly/images/static/logoz.png"
        />

        {/* Description */}
        <meta
          name="description"
          content="جامعة الزاوية هي إحدى الجامعات الحكومية المنتشرة في ربوع ليبيا. تأسست سنة  وهي عضو في اتحاد الجامعات العربية، واتحاد الجامعات الأفريقية، واتحاد الجامعات الإسلامية. تضم الجامعة 26 كلية موزعة في مدن ليبيا شاملة  للعديد من التخصصات "
        />
        <meta
          name="twitter:description"
          content="جامعة الزاوية هي إحدى الجامعات الحكومية المنتشرة في ربوع ليبيا. تأسست سنة  وهي عضو في اتحاد الجامعات العربية، واتحاد الجامعات الأفريقية، واتحاد الجامعات الإسلامية. تضم الجامعة 26 كلية موزعة في مدن ليبيا شاملة  للعديد من التخصصات "
        />

        {/* keyWords */}
        <meta
          name="keywords"
          content="university,zu,zawia,aZzawia,alzawia,جامعة,ليبيا,كلية,الزاوية,جامعة الزاوية,university,education,libya,zawia university,university of zawia,azawia,العلمي,بجامعة,المؤتمر العلمي,التدريس,بجامعة الزاوية,الطب البشري,هيئة التدريس التربية,زوارة,العجيلات ,الصحة العامة,الشريعة,القانون,نتيجة,الصيدلة الزاوية,الاقتصاد,الموارد الطبيعية,كلية البيطرة,"
        />

        {/* Url */}
        <meta property="og:url" content="https://zu.edu.ly" />
        <meta name="twitter:url" content="https://zu.edu.ly" />

        {/* Title */}
        <title>جامعة الزاوية</title>
        <meta property="og:site_name" content="جامعة الزاوية" />
        <meta itemprop="name" content="جامعة الزاوية" />
        <meta name="twitter:title" content="جامعة الزاوية" />
        <meta
          property="og:image"
          content="http://zu.edu.ly/images/static/logoz.png"
        />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="about"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4 rtl"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="info@zu.edu.ly"
        Location="0021823762382"
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="عن الجامعة"
        pageName="عن الجامعة"
        parentCategory="الرئيسية"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      {/* About Main */}
      <AboutMain />
      {/* About Main */}

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default AboutTwo;
