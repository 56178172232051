import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";
import RSMobileMenu from "./RSMobileMenu";
import CanvasMenu from "./CanvasMenu";
import TopHeader from "./TopBar";

import darkLogo from "../../../assets/img/logo/dark-logo.png";
import normalLogo from "../../../assets/img/logo/logo-dark.png";

const HeaderStyleThree = (props) => {
  const {
    headerClass,
    parentMenu,
    secondParentMenu,
    activeMenu,
    headerNormalLogo,
    departmentsItems,
    mobileNormalLogo,
    TopBar,
    TopBarClass,
    CanvasLogo,
    CanvasClass,
    faculty,
    facultyId,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const searchModalAdd = () => {
    document.body.classList.add("modal-open");
  };

  const canvasMenuAdd = () => {
    document.body.classList.add("nav-expanded");
  };

  // categories btn
  const catBtn = () => {
    document.body.classList.toggle("catBtnEnable");
  };

  return (
    <React.Fragment>
      <div
        className={
          headerClass ? headerClass : "full-width-header header-style3 modify"
        }
      >
        <header id="rs-header" className="rs-header">
          {TopBar ? <TopHeader topBarClass={TopBarClass} /> : ""}

          <div
            className={
              isVisible
                ? "menu-area menu-sticky sticky"
                : "menu-area menu-sticky"
            }
          >
            <div className="logo-part hidden-md">
              <Link to="/">
                <a className="light-logo">
                  <img
                    src={headerNormalLogo ? headerNormalLogo : normalLogo}
                    alt=""
                  />
                </a>
              </Link>
              <Link to="/">
                <a className="small-logo">
                  <img
                    src={headerNormalLogo ? headerNormalLogo : normalLogo}
                    alt=""
                  />
                </a>
              </Link>
            </div>
            <div className="container">
              <div className="rs-menu-area">
                <div className="main-menu">
                  <div className="mobile-menu md-display-block">
                    <Link to="/" className="mobile-normal-logo">
                      <img
                        className="normal-logo"
                        src={mobileNormalLogo ? mobileNormalLogo : normalLogo}
                        alt=""
                      />
                    </Link>
                    <Link to="/" className="mobile-sticky-logo">
                      <img src={darkLogo} alt="logo" />
                    </Link>
                    <a
                      href="#"
                      className="rs-menu-toggle"
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                      }}
                    >
                      <i className="fa fa-bars"></i>
                    </a>
                  </div>
                  <nav className="rs-menu hidden-md">
                    <ul className="nav-menu">
                      <MenuItems
                        departmentsItems={departmentsItems}
                        facultyId={facultyId}
                        faculty={faculty}
                        parentMenu={parentMenu}
                        secondParentMenu={secondParentMenu}
                        activeMenu={activeMenu}
                      />
                    </ul>
                  </nav>
                </div>
                <div className="expand-btn-inner">
                  <ul className="expand-items">
                    <li className="sidebarmenu-search">
                      <a
                        onClick={searchModalAdd}
                        className="rs-search"
                        href="#"
                      >
                        <i className="flaticon-search"></i>
                      </a>
                    </li>
                  </ul>
                  <span>
                    <a
                      onClick={canvasMenuAdd}
                      id="nav-expander"
                      className="nav-expander style5"
                      href="#"
                    >
                      <span className="bar">
                        <span className="dot1"></span>
                        <span className="dot2"></span>
                        <span className="dot3"></span>
                      </span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <RSMobileMenu
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            parentMenu={parentMenu}
            secondParentMenu={secondParentMenu}
            departmentsItems={departmentsItems}
            facultyId={facultyId}
            faculty={faculty}
          />
          <div
            onClick={() => setMenuOpen(false)}
            className={menuOpen ? "body-overlay show" : "body-overlay"}
          ></div>
        </header>
        <CanvasMenu
          canvasClass={
            CanvasClass
              ? CanvasClass
              : "right_menu_togle orange_color hidden-md"
          }
          canvasLogo={CanvasLogo ? CanvasLogo : darkLogo}
        />
      </div>
    </React.Fragment>
  );
};

export default HeaderStyleThree;
