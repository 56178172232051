/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-underscore-dangle */
import {
  DEPARTMENTS_LIST_GET_LIST,
  DEPARTMENTS_LIST_GET_LIST_SUCCESS,
  DEPARTMENTS_LIST_GET_LIST_ERROR,
  DEPARTMENTS_LIST_GET_ONE,
  DEPARTMENTS_LIST_GET_ONE_SUCCESS,
  DEPARTMENTS_LIST_GET_ONE_ERROR,
  DEPARTMENTS_LIST_GET_LIST_SEARCH,
} from "../contants";

const INIT_STATE = {
  allDepartmentsItems: null,
  departmentsItems: null,
  departmentDetails: null,
  error: "",
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  departments: null,
  labels: [
    { label: "الفصل الاول", color: "secondary", year: "1" },
    { label: "الفصل التاني", color: "primary", year: "2" },
    { label: "الفصل الثالث", color: "info", year: "3" },
    { label: "الفصل الرابع", color: "secondary", year: "4" },
    { label: "الفصل الخامس", color: "primary", year: "5" },
    { label: "الفصل السادس", color: "info", year: "6" },
  ],
  orderColumns: [
    { column: "subjectname", label: "عنوان المقرر" },
    { column: "departmentName", label: " القسم" },
    { column: "unit", label: "عدد الوحدات" },
  ],
  categories: ["داعمة", "تخصص", "عامة"],
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DEPARTMENTS_LIST_GET_ONE:
    case DEPARTMENTS_LIST_GET_LIST:
      return { ...state, loading: false };

    case DEPARTMENTS_LIST_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allDepartmentsItems: action.payload,
        departmentsItems: action.payload,
      };

    case DEPARTMENTS_LIST_GET_ONE_SUCCESS:
      return {
        ...state,
        departmentDetails: action.payload,
        loading: true,
      };
    case DEPARTMENTS_LIST_GET_ONE_ERROR:
    case DEPARTMENTS_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case DEPARTMENTS_LIST_GET_LIST_SEARCH:
      if (action.payload === "") {
        return { ...state, departmentsItems: state.allDepartmentsItems };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchItems = state.allDepartmentsItems.filter(
        (item) =>
          (item.subjectname &&
            item.subjectname.toLowerCase().indexOf(keyword) > -1) ||
          (item.subjectCode &&
            item.subjectCode.toLowerCase().indexOf(keyword) > -1)
      );
      return {
        ...state,
        loading: true,
        departmentsItems: searchItems,
        searchKeyword: action.payload,
      };

    default:
      return { ...state };
  }
};
