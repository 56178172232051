import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { shareUrl, Url1, Url } from "../../constants/defaultValues";

import innerImage from "../../assets/img/blog/inner/2.jpg";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberIcon,
  ViberShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const PostContent = ({ news }) => {
  return (
    news && (
      <div className="blog-deatails rtl">
        <div className="bs-img">
          <img src={news && Url + news.image} alt={news && news.title} />
        </div>
        <div className="blog-full">
          <ul className="single-post-meta">
            <li>
              <span className="p-date">
                {" "}
                <i className="fa fa-calendar-check-o"></i>{" "}
                {moment(news && news.updatedAt).format("ll")}
              </span>
            </li>
            <li>
              <span className="p-date">
                {" "}
                <i className="fa fa-user-o"></i>
                {news && news.author && news.author.name}
              </span>
            </li>
            {news && news.nameOFTracking && (
              <li className="Post-cate">
                <div className="tag-line">
                  <i className="fa fa-book"></i>
                  <Link to="/blog">{news.nameOFTracking}</Link>
                </div>
              </li>
            )}
          </ul>
          <div className="blog-desc text-justify ">
            <p dangerouslySetInnerHTML={{ __html: news && news.description }} />
          </div>
          <blockquote>
            <h5 className="text-center"> لمشاركة الاعلان</h5>
            <div className="social-box text-center">
              <FacebookShareButton
                url={`${shareUrl}news/${news._id}`}
                quote={news && news.title}
                hashtag="#اعلان بجامعة الزاوية"
              >
                <FacebookIcon size={40} round={true} />
              </FacebookShareButton>
              <EmailShareButton
                url={`${shareUrl}news/${news._id}`}
                subject={news && news.title}
                body={news && news.description}
              >
                <EmailIcon size={40} round={true} />
              </EmailShareButton>
              <LinkedinShareButton url={`${shareUrl}news/${news._id}`}>
                <LinkedinIcon size={40} round={true} />
              </LinkedinShareButton>
              <TelegramShareButton url={`${shareUrl}news/${news._id}`}>
                <TelegramIcon size={40} round={true} />
              </TelegramShareButton>
              <TwitterShareButton
                url={`${shareUrl}news/${news._id}`}
                title={news && news.title}
              >
                <TwitterIcon size={40} round={true} />
              </TwitterShareButton>
              <ViberShareButton
                url={`${shareUrl}news/${news._id}`}
                title={news && news.title}
              >
                <ViberIcon size={40} round={true} />
              </ViberShareButton>
              <WhatsappShareButton
                url={`${shareUrl}news/${news._id}`}
                title={news && news.title}
              >
                <WhatsappIcon size={40} round={true} />
              </WhatsappShareButton>
            </div>
          </blockquote>
        </div>
      </div>
    )
  );
};

export default PostContent;
