/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-underscore-dangle */
import {
  SPEECH_GET_LIST,
  SPEECH_GET_LIST_SUCCESS,
  SPEECH_GET_LIST_ERROR,
} from "../contants";

const INIT_STATE = {
  allSpeechItems: null,
  SpeechItems: null,
  error: "",
  loading: false,
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SPEECH_GET_LIST:
      return { ...state, loading: false };

    case SPEECH_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allSpeechItems: action.payload,
        SpeechItems: action.payload,
      };

    case SPEECH_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
