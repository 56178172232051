import React from "react";
import Tilty from "react-tilty";
import BlogPartFive from "../../components/Blog/HomeBlogSectionFive";
import SectionTitle from "../../components/Common/SectionTitle";
import { Link } from "react-router-dom";
import shapeImg from "../../assets/img/shape/radius-circle-shape.png";
import { useSelector } from "react-redux";

const Blog = ({ faculty }) => {
  const { newsItems } = useSelector((state) => state.newsListApp);

  return (
    <React.Fragment>
      <div id="rs-blog" className="rs-blog style2 pb-100 md-pb-70">
        <div className="container relative">
          <div className="right-top-shape">
            <Tilty perspective="800" reverse={true}>
              <img src={shapeImg} alt="Shape Image" />
            </Tilty>
          </div>
          <SectionTitle
            sectionClass="sec-title mb-60 md-mb-30 text-center"
            subtitleClass="sub-title primary"
            subtitle="أحدث الاخبار"
            titleClass="title mb-0"
            title="اخر الاخبار و المقالات"
          />
          <BlogPartFive news={newsItems} />
        </div>
        <div className="view-all-btn text-center pt-50 mb-60 md-pt-30 md-mb-30">
          لقرآءة المزيد من الاخبار ب{faculty && faculty.facultyTitle}
          <Link
            className="title-color"
            to={`/faculties/${faculty && faculty.Url}/news`}
          >
            {" "}
            اضغط هنا
          </Link>
        </div>
      </div>
      {/* <NewsletterTwo /> */}
    </React.Fragment>
  );
};

export default Blog;
