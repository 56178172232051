import React from "react";
import { Link } from "react-router-dom";
import RecentNewsWidget from "../../components/Widget/NewsPost";
import { getAdvsListSearch } from "../../redux/advsList/actions";
import { useDispatch } from "react-redux";

const CourseSidebar = () => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className="widget-area ">
        <div className="search-widget mb-50 order-last">
          <h3 className="widget-title">
            البحث عن اعلان <i className=" flaticon-search"></i>
          </h3>
          <div className="search-wrap">
            <input
              type="text"
              name="keyword"
              id="search"
              placeholder="بحث ..."
              onChange={(e) => {
                dispatch(getAdvsListSearch(e.target.value));
              }}
            />
          </div>
        </div>
        <RecentNewsWidget />

        {/* <div className="widget-archives md-mb-50">
          <h3 className="widget-title">Course Categories</h3>
          <ul className="categories">
            <li>
              <Link to="/course-categories">College</Link>
            </li>
            <li>
              <Link to="/course-categories">High School</Link>
            </li>
            <li>
              <Link to="/course-categories">Primary</Link>
            </li>
            <li>
              <Link to="/course-categories">School</Link>
            </li>
            <li>
              <Link to="/course-categories">University</Link>
            </li>
          </ul>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default CourseSidebar;
