import React from "react";
import { Link } from "react-router-dom";

const CourseSingleSix = (props) => {
  const {
    courseClass,
    courseImg,
    courseTitle,
    proCertificate,
    userCount,
    department,
    proUrl,
  } = props;
  return (
    <div className={courseClass ? courseClass : "course-item"}>
      <div className="course-image">
        <Link to={proUrl}>
          <img src={courseImg} alt={courseTitle} />
        </Link>
      </div>
      <div className="course-info">
        <ul className="meta-part">
          <li>
            <span className="price">
              {proCertificate ? proCertificate : "بكالوريوس"}
            </span>
          </li>
          <li className="user">
            <i className="fa fa-building"></i> {userCount ? userCount : "245"}
          </li>
        </ul>
        <h4 className="course-title">
          <Link to={proUrl}>
            {courseTitle
              ? courseTitle
              : "Become a PHP Master and Make Money Fast"}
          </Link>
        </h4>
        <small>{department ? department : "القسم"}</small>
        <div className="bottom-part">
          <div className="btn-part">
            <Link to={proUrl}>
              {props.btnText}
              <i className="flaticon-left-arrow"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSingleSix;
