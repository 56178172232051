import moment from "moment";
import React from "react";

// Breadcrumbs Bg
import { shareUrl, Url1 } from "../../constants/defaultValues";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberIcon,
  ViberShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const TeamSingleMain = ({ article }) => {
  return (
    <div className="profile-section pt-100 pb-90 md-pt-80 md-pb-70 rtl">
      <div className="container">
        <div className="row clearfix">
          <div className="image-column col-lg-5 md-mb-50">
            <div className="inner-column mb-50 md-mb-0">
              <div className="image">
                <img src={`${Url1}${article && article.image}`} alt="images" />
              </div>
              <div className="team-content text-center m-4">
                <div className="text">
                  <i className="fa fa-user" /> {article && article.name}{" "}
                  {article && article.adjective}
                  <br /> تم النشر
                  <i className="fa fa-calendar-check-o"></i>{" "}
                  {moment(article && article.updatedAt)
                    .startOf("day")
                    .fromNow()}{" "}
                  ب{article && article.pleaceOfPublish}
                </div>
              </div>
              <h5 className="text-center"> لمشاركة المقالة</h5>
              <div className="social-box">
                <FacebookShareButton
                  url={`${shareUrl}articles/${article._id}`}
                  quote={article && article.title}
                  hashtag="#مقالات بجامعة الزاوية"
                >
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
                <EmailShareButton
                  url={`${shareUrl}articles/${article._id}`}
                  subject={article && article.title}
                  body={article && article.details}
                >
                  <EmailIcon size={40} round={true} />
                </EmailShareButton>
                <LinkedinShareButton url={`${shareUrl}articles/${article._id}`}>
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
                <TelegramShareButton url={`${shareUrl}articles/${article._id}`}>
                  <TelegramIcon size={40} round={true} />
                </TelegramShareButton>
                <TwitterShareButton
                  url={`${shareUrl}articles/${article._id}`}
                  title={article && article.title}
                >
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
                <ViberShareButton
                  url={`${shareUrl}articles/${article._id}`}
                  title={article && article.title}
                >
                  <ViberIcon size={40} round={true} />
                </ViberShareButton>
                <WhatsappShareButton
                  url={`${shareUrl}articles/${article._id}`}
                  title={article && article.title}
                >
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
          <div className="content-column col-lg-7 pl-60 pt-50 md-pl-14 md-pt-0">
            <div className="inner-column">
              <h5 className="text-justify">{article && article.title}</h5>
              <br />
              <h5>تفاصيل المقالة</h5>
              <p
                className="text-justify"
                dangerouslySetInnerHTML={{
                  __html: article && article.details,
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSingleMain;
