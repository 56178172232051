import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";

// Image
import { Url, Url1 } from "../../constants/defaultValues";
import Department from "../aDepartment/index";

const CourseSidebar = ({
  title,
  hours,
  academicCertificate,
  faculty,
  department,
  language,

  program,
  image,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);
  console.log(program)
  return (
    <div className="inner-column">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="YLN1Argi7ik"
        onClose={() => {
          openModal();
        }}
      />
      <div className="intro-video media-icon primaty-color2">
        <img className="video-img" src={Url + image} alt={title} />
        {/* <Link
          className="popup-videos"
          onClick={() => {
            openModal();
          }}
        >
          <i className="fa fa-play"></i>
        </Link> */}
        {/* <h4>رحلة حول القسم</h4> */}
      </div>
      <div className="course-features-info ">
        <ul>
          <li className="lectures-feature ">
            <i className="fa fa-files-o"></i>
            <span className="label">{title}</span>
            <span className="value"> اسم البرنامج </span>
          </li>
          <li className="lectures-feature ">
            <i className="fa fa-building"></i>
            <span className="label">{faculty}</span>
            <span className="value"> الكلية </span>
          </li>
          <li className="lectures-feature ">
            <i className="fa fa-building"></i>
            <span className="label">{department}</span>
            <span className="value"> القسم </span>
          </li>
          <li className="lectures-feature ">
            <i className="fa fa-certificate"></i>
            <span className="label">{academicCertificate}</span>
            <span className="value"> الشهادة </span>
          </li>
          <li className="lectures-feature ">
            <i className="fa fa-language"></i>
            <span className="label">{language}</span>
            <span className="value">لغة الدراسة </span>
          </li>
          <li className="duration-feature">
            <i className="fa fa-clock-o"></i>
            <span className="value">عدد الساعات الدراسية</span>
            <span className="label"> {hours} </span>
          </li>
          {program && program.map(p => <li className=" mt-3 text-center">
              <a
                href={Url1 + p.file}
                className="btn-success p-3  btn-radius"
                target="_blank"
              >
                
                للتحميل البرنامج الاكاديمي
                ({p.title})
                <i className="fa fa-download text-white m-2"></i>
              </a>
            </li>
           ) }
        </ul>
      </div>
    </div>
  );
};

export default CourseSidebar;
