import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CategoriesSingleTwo from "../../components/Categories/CategoriesSingleTwo";

// Categories Icon
import icon1 from "../../assets/img/categories/icons/1.png";
import icon8 from "../../assets/img/categories/icons/8.png";
import { Url } from "../../constants/defaultValues";

const Categories = ({ filesItems }) => {
  let tab1 = "الكل",
    tab2 = "النماذج",
    tab3 = "الادلة",
    tab4 = "التقارير";

  const tabStyle = "gridFilter text-center mb-50 md-mb-30";
  const forms = filesItems && filesItems.filter((form) => form.type === "form");
  const evidence =
    filesItems && filesItems.filter((form) => form.type === "evidence");
    const report = filesItems && filesItems.filter((form) => form.type === "report");
  return (
    <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40 rtl">
      <div className="container">
        <Tabs>
          <TabList className={tabStyle}>
            <Tab>
              <button>{tab1}</button>
            </Tab>
            <Tab>
              <button>{tab2}</button>
            </Tab>
            <Tab>
              <button>{tab3}</button>
            </Tab>
            <Tab>
              <button>{tab4}</button>
            </Tab>
          </TabList>
          <TabPanel>
            <div className="row">
              {filesItems &&
                filesItems.map((form) => (
                  <div className="col-lg-4 col-md-6 mb-30">
                    <CategoriesSingleTwo
                      courseQuantity={
                        form.type === "evidence" ? "دليل" : form.type === "form"  ? "نموذج" :"تقرير"
                      }
                      categoriesClass="categories-item"
                      title={form.title}
                      quantity={form.type}
                      iconImg={form.type === "evidence" ? icon8 : icon1}
                      catLink={Url + form.file}
                    />
                  </div>
                ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row">
              {forms &&
                forms.map((form) => (
                  <div className="col-lg-4 col-md-6 mb-30">
                    <CategoriesSingleTwo
                      courseQuantity={
                        form.type === "evidence" ? "دليل" : form.type === "form"  ? "نموذج" :"تقرير"
                      }
                      categoriesClass="categories-item"
                      title={form.title}
                      quantity={form.type}
                      iconImg={icon1}
                      catLink={Url + form.file}
                    />
                  </div>
                ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row">
              {evidence &&
                evidence.map((form) => (
                  <div className="col-lg-4 col-md-6 mb-30">
                    <CategoriesSingleTwo
                      courseQuantity={
                        form.type === "evidence" ? "دليل" : form.type === "form"  ? "نموذج" :"تقرير"
                      }
                      categoriesClass="categories-item"
                      title={form.title}
                      quantity={form.type}
                      iconImg={icon8}
                      catLink={Url + form.file}
                    />
                  </div>
                ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row">
              {report &&
                report.map((form) => (
                  <div className="col-lg-4 col-md-6 mb-30">
                    <CategoriesSingleTwo
                      courseQuantity={
                        form.type === "evidence" ? "دليل" : form.type === "form"  ? "نموذج" :"تقرير"
                      }
                      categoriesClass="categories-item"
                      title={form.title}
                      quantity={form.type}
                      iconImg={icon8}
                      catLink={Url + form.file}
                    />
                  </div>
                ))}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Categories;
